import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import handleError from "../../helper/handleError";

let URL;

URL = process.env.REACT_APP_SERVER_URL;

const initialCustomerState = {
  errorCustomer: false,
  messageCustomer: false,
  customers: [],
  customer: {},
  totalNumOfCustomers: 0,
};

const customerSlice = createSlice({
  name: "customer",
  initialState: initialCustomerState,
  reducers: {
    setErrorCustomer(state, action) {
      state.errorCustomer = action.payload.value;
    },
    setMessageCustomer(state, action) {
      state.messageCustomer = action.payload.value;
    },
    setCustomers(state, action) {
      state.customers = action.payload.data.customers;
      state.totalNumOfCustomers = action.payload.data.totalNumOfCustomers;
    },
    setCustomer(state, action) {
      state.customer = action.payload.data;
    },
    setTotalNumOfShares(state, action) {
      state.totalNumOfShares = action.payload.value;
    },
    removeCustomerFromCustomers(state, action) {
      const _customers = state.customers.filter(
        (c) => c.id !== action.payload.value
      );
      state.customers = _customers;
    },
  },
});

export const getCustomers = (token, queryStr = "") => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "get",
        url: `${URL}/users/get-users${queryStr}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          customerSlice.actions.setCustomers({
            data: {
              customers: response.data.data.customers,
              length: response.data.data.totalNumOfCustomers,
            },
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const getCustomer = (customerId, token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "get",
        url: `${URL}/users/${customerId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          customerSlice.actions.setCustomer({
            data: response.data.data,
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const createCustomer = (userInputs, token, navigate, FN) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "post",
        url: `${URL}/users/`,
        data: userInputs,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          customerSlice.actions.setCustomer({
            data: response.data.data,
          })
        );
        navigate(`/kunden/${response.data.data.id}`);
        dispatch(FN(token));
        dispatch(
          customerSlice.actions.setMessageCustomer({
            value: `You created customer ${response.data.data.firstName} succesfully!`,
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(
        err,
        `An error occurred while creating the customer.`,
        dispatch,
        customerSlice.actions.setErrorCustomer
      );
    }
  };
};

export const editCustomer = (
  customerId,
  userInputs,
  token,
  navigate = null,
  FN = null
) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "patch",
        url: `${URL}/users/${customerId}`,
        data: userInputs,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          customerSlice.actions.setCustomer({
            data: response.data.data,
          })
        );
        if (navigate) {
          navigate(`/kunden/${response.data.data.id}`);
        }
        if (FN) {
          dispatch(FN(token));
        }
        dispatch(
          customerSlice.actions.setMessageCustomer({
            value: `Benutzerdaten für ${response.data.data.firstName} erfolgreich geändert.!`,
          })
        );
      }
    } catch (err) {
      handleError(
        err,
        `An error occurred while editing the customer.`,
        dispatch,
        customerSlice.actions.setErrorCustomer
      );
    }
  };
};

export const deleteCustomer = (customerId, token, FN) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "delete",
        url: `${URL}/users/${customerId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        // dispatch(FN(token));
        dispatch(
          customerSlice.actions.removeCustomerFromCustomers({
            value: customerId,
          })
        );
        dispatch(
          customerSlice.actions.setMessageCustomer({
            value: `Customer succesfully deleted!`,
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(
        err,
        `An error occurred while deleting the customer.`,
        dispatch,
        customerSlice.actions.setErrorCustomer
      );
    }
  };
};

export default customerSlice;
