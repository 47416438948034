import React from "react";

import classes from "./CheckBoxInput.module.css";

import { BiCheckbox, BiCheckboxSquare } from "react-icons/bi";

const CheckBoxInput = (props) => {
  return (
    <div className={classes.container} onClick={props.onClick}>
      <div className={classes["clickable"]}>
        <div className={classes.icon}>
          {props.value === false ? <BiCheckbox /> : <BiCheckboxSquare />}
        </div>
        <p>{props.label}</p>
      </div>
    </div>
  );
};

export default CheckBoxInput;
