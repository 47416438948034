import React from "react";
import { useSelector } from "react-redux";

import classes from "./Home.module.css";

// COMPONENTS
import HomeContainer from "../components/home/HomeContainer";

const Home = (props) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <div
      className={classes.container}
      style={
        isAuthenticated
          ? { backgroundColor: `var(--white)` }
          : { backgroundColor: "var(--c-primary-beige2" }
      }
    >
      <HomeContainer hook={props.hook} />
    </div>
  );
};

export default Home;
