import React from "react";
import { Routes, Route } from "react-router-dom";

// COMPONENTS
import OrdersContainer from "../components/orders/OrdersContainer";

// CUSTOM HOOKS
import useOrdersFilter from "../custom-hooks/useOrdersFilter";
import SingleOrderContainer from "../components/orders/single-order/SingleOrderContainer";
import OrderFormContainer from "../components/orders/order-form/OrderFormContainer";
import ConfirmOrders from "../components/orders/confirm-orders/ConfirmOrders";

const OrdersRoutes = (props) => {
  const ordersFilterHook = useOrdersFilter();

  return (
    <Routes>
      <Route path="/" element={<OrdersContainer hook={ordersFilterHook} />} />
      <Route path="/:orderId" element={<SingleOrderContainer />} />
      <Route path="/schaffen" element={<OrderFormContainer type="create" />} />
      <Route path="/absenden" element={<ConfirmOrders />} />
      <Route
        path="/redigieren/:orderId"
        element={<OrderFormContainer type="edit" />}
      />
    </Routes>
  );
};

export default OrdersRoutes;
