import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import classes from "./CustomersTable.module.css";

// COMPONENTS
import CustomersFilter from "./CustomersFilters";
import CustomersTableRow from "./CustomersTableRow";
import SingleTableHeader from "../UI/SingleTableHeader";

import sortDynamic from "../../helper/sort-array";

const CustomersTable = (props) => {
  const { customers } = useSelector((state) => state.customer);

  const [_modifiedCustomers, _setModifiedCustomers] = useState([]);
  const [_customersRenderer, _setCustomersRenderer] = useState([]);

  useEffect(() => {
    const _customers = customers.map((o) => {
      return {
        id: o.id,
        firstName: o.firstName,
        lastName: o.lastName,
        email: o.email,
        birthday: o.birthday,
        phoneNumber: o.phoneNumber,
        privateNumber: o.privateNumber,
        street: o.street,
        city: `${o.city} ${o.postalCode}`,
        country: o.country,
        identificationString: o.identificationString,
        totalOrders: +o.totalOrders,
      };
    });

    if (props.hook.sort.field && props.hook.sort.by) {
      const _sorted = _customers.sort(
        sortDynamic(props.hook.sort.field, props.hook.sort.by)
      );
      _setModifiedCustomers(_sorted);
    } else {
      _setModifiedCustomers(_customers);
    }
  }, [customers, props.hook.sort]);

  useEffect(() => {
    const _customers = _modifiedCustomers.map((c, i) => (
      <CustomersTableRow
        key={c.id}
        sort={props.hook.sort}
        data={c}
        style={
          i % 2 !== 0 ? { backgroundColor: "var(--c-background-table)" } : {}
        }
      />
    ));
    _setCustomersRenderer(_customers);
  }, [_modifiedCustomers, props.hook.sort]);

  return (
    <div className={classes.container}>
      <CustomersFilter hook={props.hook} />
      <div className={classes.table}>
        <ul>
          <div className={classes.header}>
            <SingleTableHeader
              label={"Vorname"}
              name={"firstName"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Nachname"}
              name={"lastName"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Email"}
              name={"email"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Geburtsdatum"}
              name={"birthday"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Telefonnummer"}
              name={"phoneNumber"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Telefonnummer Geschäftlich"}
              name={"privateNumber"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Adresse"}
              name={"street"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Stadt"}
              name={"city"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Land"}
              name={"country"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Identifikationszeichenkette"}
              name={"identificationString"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Bestellungen"}
              name={"totalOrders"}
              hook={props.hook}
            />
            {/* <div className={classes["table-header"]}>Vorname</div>
            <div className={classes["table-header"]}>Nachname</div>
            <div className={classes["table-header"]}>Email</div>
            <div className={classes["table-header"]}>Geburtsdatum</div>
            <div className={classes["table-header"]}>Telefonnummer</div>
            <div className={classes["table-header"]}>Adresse</div>
            <div className={classes["table-header"]}>Stadt</div>
            <div className={classes["table-header"]}>Land</div>
            <div className={classes["table-header"]}>
              Identifikationzeichenkette
            </div>
            <div className={classes["table-header"]}>
              Anzahl der bestellungen
            </div> */}
            <div
              style={{ border: "none" }}
              className={classes["table-header"]}
            ></div>
          </div>
          {_customersRenderer}
          {/* {_sharesRenderer} */}
        </ul>
      </div>
    </div>
  );
};

export default CustomersTable;
