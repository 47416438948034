import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import classes from "./Header.module.css";

// COMPONENTS
import SingleNav from "./SingleNav";

// IMAGES
import wineMarkImg from "../../assets/images/wine-mark.png";

const Header = (props) => {
  const location = useLocation();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  return (
    <div className={classes.container}>
      <div className={classes["inner-container"]}>
        <Link to={"/"} className={classes.logo}>
          <img src={wineMarkImg} alt={"Wine mark"} />
          <p>BODEGA RESIDENT MALLORCA</p>
        </Link>
        {/* <div className={classes.separator} /> */}
        <div className={classes.navs}>
          {isAuthenticated && (
            <div className={classes["navs-left"]}>
              {user.isAdmin === 1 && (
                <SingleNav path={"/kunden"}>Kunden</SingleNav>
              )}
              {user.isAdmin === 1 && (
                <SingleNav path={"/aktien"}>Aktien</SingleNav>
              )}
              {<SingleNav path={"/weine"}>Weine</SingleNav>}
              {<SingleNav path={"/bestellung"}>Bestellungen</SingleNav>}
              {user.isAdmin === 1 && (
                <SingleNav path={"/einstellungen"}>Einstellungen</SingleNav>
              )}
              {user.isAdmin === 0 && (
                <SingleNav path={"/kundendata"}>Meine Daten</SingleNav>
              )}
            </div>
          )}
          {isAuthenticated && (
            <div className={classes["navs-right"]}>
              <SingleNav path={"/logout"} onClick={props.hook.logout}>
                Abmelden
              </SingleNav>
            </div>
          )}
          {!isAuthenticated && (
            <div className={classes["navs-right"]}>
              <SingleNav path={"/"} onClick={props.hook.logout}>
                Login
              </SingleNav>
            </div>
          )}
        </div>
      </div>
      {location.pathname === "/weine" ? (
        <div className={classes["bottom-line-green"]} />
      ) : (
        <div className={classes["bottom-line-white"]} />
      )}
    </div>
  );
};

export default Header;
