import React from "react";

import classes from "./OrderCard.module.css";

const SingleInfo = (props) => {
  return (
    <div className={classes["single-info"]}>
      <p className={classes["label"]}>{props.label}:</p>
      <p className={classes["value"]}>{props.value}</p>
    </div>
  );
};

const OrderCard = (props) => {
  return (
    <div className={classes.container}>
      <SingleInfo label={"Weinname"} value={props.data.Wine?.wineName} />
      <SingleInfo label={"Menge"} value={props.data.quantity} />
      <SingleInfo
        label={"Zuzahlung pro Flasche"}
        value={`${props.data.Wine?.additionalPayment} €`}
      />
      <SingleInfo
        label={"Zuzahlung Insgesamt"}
        value={`${(
          props.data.Wine?.additionalPayment * props.data.quantity
        ).toFixed(2)} €`}
      />
      <SingleInfo
        label={"Versand"}
        value={
          props.data.pickup === 0
            ? "Lieferung an den Wohnort"
            : "Abholung in Bodega"
        }
      />
      <SingleInfo
        label={"Datum"}
        value={new Date(props.data.createdAt).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })}
      />
    </div>
  );
};

export default OrderCard;
