import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import handleError from "../../helper/handleError";

let URL;

URL = process.env.REACT_APP_SERVER_URL;

const initialAuthState = {
  errorAuth: false,
  messageAuth: false,
  year: null,
  user: {},
  token: "",
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setErrorAuth(state, action) {
      state.errorAuth = action.payload.value;
    },
    setMessageAuth(state, action) {
      state.messageAuth = action.payload.value;
    },
    setYear(state, action) {
      state.year = +action.payload.value;
    },
    setUser(state, action) {
      state.user = action.payload.data;
    },
    updateUserShares(state, action) {
      if (state.user.isAdmin === 1) return;

      const _user = { ...state.user };

      if (!_user.shares) return;
      if (!action.payload.data.quantity || !action.payload.data.shares) return;

      const sharesToBeDeducted =
        action.payload.data.quantity * action.payload.data.shares;

      const totalAvailableShares =
        _user.shares?.availableShares - sharesToBeDeducted;
      const totalSharesUsed = _user.shares?.sharesUsed + sharesToBeDeducted;

      _user.shares.availableShares =
        totalAvailableShares <= 0 ? 0 : totalAvailableShares;
      _user.shares.sharesUsed =
        totalSharesUsed > _user.shares?.totalNumOfShares
          ? _user.shares?.totalNumOfShares
          : totalSharesUsed;

      state.user = _user;
    },
    setToken(state, action) {
      state.token = action.payload.data.token;

      localStorage.setItem("token", JSON.stringify(action.payload.data.token));
      if (action.payload.data.expiresIn) {
        localStorage.setItem(
          "expiresIn",
          JSON.stringify(action.payload.data.expiresIn)
        );
      }
    },
    setIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload.value;
    },
    logout(state) {
      state.user = {};
      state.isAuthenticated = false;
      state.token = "";
      localStorage.removeItem("token");
      localStorage.removeItem("expiresIn");
    },
  },
});

export const signin = (userInputs, navigate) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "POST",
        url: `${URL}/users/signin`,
        data: userInputs,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        if (navigate) navigate("/");

        dispatch(
          authSlice.actions.setMessageAuth({ value: "Erfolgreich eingeloggt." })
        );
        let _userData = { ...response.data.data };
        if (!response.data.data.shares) {
          _userData.shares = {
            availableShares: 0,
            sharesUsed: 0,
            totalNumOfShares: 0,
          };
        }
        dispatch(authSlice.actions.setUser({ data: _userData }));
        dispatch(authSlice.actions.setIsAuthenticated({ value: true }));
        dispatch(
          authSlice.actions.setToken({
            data: {
              token: response.data.token,
              expiresIn: response.data.expiresIn,
            },
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(
        err,
        err.response.data.message,
        dispatch,
        authSlice.actions.setErrorAuth
      );
    }
  };
};

export const getMyData = (token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "get",
        url: `${URL}/users/me`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        let _userData = { ...response.data.data };
        if (!response.data.data.shares) {
          _userData.shares = {
            availableShares: 0,
            sharesUsed: 0,
            totalNumOfShares: 0,
          };
        }
        dispatch(authSlice.actions.setUser({ data: _userData }));
        // dispatch(authSlice.actions.setUser({ data: response.data.data }));
        dispatch(authSlice.actions.setIsAuthenticated({ value: true }));
        dispatch(
          authSlice.actions.setToken({ data: { token, expiresIn: null } })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(err, null, dispatch, authSlice.actions.setErrorAuth);
    }
  };
};

export const getPickedYear = (token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "get",
        url: `${URL}/settings/year`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(authSlice.actions.setYear({ value: response.data.data }));
      }
    } catch (err) {
      handleError(err, null, dispatch, authSlice.actions.setErrorAuth);
    }
  };
};

export const setPickedYear = (userInputs, token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "post",
        url: `${URL}/settings/year`,
        data: userInputs,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(authSlice.actions.setYear({ value: response.data.data }));
        dispatch(
          authSlice.actions.setMessageAuth({
            value: `Das Jahr wurde erfolgreich eingestellt !`,
          })
        );
      }
    } catch (err) {
      handleError(err, null, dispatch, authSlice.actions.setErrorAuth);
    }
  };
};

export default authSlice;
