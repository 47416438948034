import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import classes from "./DownloadExcelBtn.module.css";

const URL_PATH = process.env.REACT_APP_SERVER_URL;

const DownloadExcelBtn = (props) => {
  const { token, year } = useSelector((state) => state.auth);

  const generateExcelHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios({
        method: "get",
        url: `${URL_PATH}/${props.model}/get-excel`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
        withCredentials: true,
      });

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        let fileName = `${props.fileName}.xlsx`;
        if (props.fileName === "bestellung") {
          fileName = `${props.fileName}-${year}.xlsx`;
        }
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classes.container}>
      <div onClick={generateExcelHandler} className={classes.button}>
        Export to XLS
      </div>
    </div>
  );
};

export default DownloadExcelBtn;
