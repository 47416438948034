import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import classes from "./SharesTableRow.module.css";

import { TbPencil } from "react-icons/tb";
import { BsTrash } from "react-icons/bs";
import { MdOutlineRemoveRedEye } from "react-icons/md";

// STORE
import { deleteShare } from "../../store/reducers/share-slice";

// COMPONENTS
import TableCell from "../UI/TableCell";

const SharesTableRow = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated, token, user } = useSelector((state) => state.auth);

  const redirectToSingleShareHandler = (e) => {
    e.preventDefault();
    navigate(`${props.data.id}`);
  };

  const redirectToEditShareHandler = (e) => {
    e.preventDefault();
    navigate(`redigieren/${props.data.id}`);
  };

  const deleteShareHandler = (e) => {
    e.preventDefault();
    if (!isAuthenticated || !token || user.isAdmin === 0) return;

    dispatch(deleteShare(props.data.id, token));
  };

  return (
    <div className={classes.container} style={props.style}>
      <TableCell data={props.data} label={"fullName"} sort={props.sort} />
      <TableCell data={props.data} label={"email"} sort={props.sort} />
      <TableCell data={props.data} label={"shares"} sort={props.sort} />
      <TableCell data={props.data} label={"totalPrice"} sort={props.sort} />
      <TableCell
        data={props.data}
        label={"shareIdentificationNumber1"}
        sort={props.sort}
      />
      <TableCell
        data={props.data}
        label={"shareIdentificationNumber2"}
        sort={props.sort}
      />
      <TableCell data={props.data} label={"comment"} sort={props.sort} />
      <div className={classes.buttons}>
        <div
          className={`${classes.view} ${classes.btn}`}
          onClick={redirectToSingleShareHandler}
        >
          <MdOutlineRemoveRedEye />
        </div>
        <div
          className={`${classes.edit} ${classes.btn}`}
          onClick={redirectToEditShareHandler}
        >
          <TbPencil />
        </div>
        <div
          className={`${classes.delete} ${classes.btn}`}
          onClick={deleteShareHandler}
        >
          <BsTrash />
        </div>
      </div>
    </div>
  );
};

export default SharesTableRow;
