import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

import classes from "./CustomerFormContainer.module.css";

// COMPONENTS
import Title from "../../UI/Title";
import Input from "../../UI/Input";
import CheckBoxInput from "../../UI/CheckBoxInput";

// STORE
import {
  createCustomer,
  getCustomers,
  getCustomer,
  editCustomer,
} from "../../../store/reducers/customer-slice";

const CustomerFormContainer = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customer } = useSelector((state) => state.customer);
  const { isAuthenticated, token, user } = useSelector((state) => state.auth);

  // INPUTS
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailFormat, setEmailFormat] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [company, setCompany] = useState("");
  const [sex, setSex] = useState(0);
  const [gender, setGender] = useState("");
  const [degree, setDegree] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    if (props.type !== "edit") return;
    if (!isAuthenticated || !token || user.isAdmin === 0) return;

    if (customer?.id && customer?.id === +params.customerId) return;

    dispatch(getCustomer(params.customerId, token));
  }, [
    dispatch,
    params?.customerId,
    isAuthenticated,
    token,
    user?.isAdmin,
    customer?.id,
    props.type,
  ]);

  useEffect(() => {
    if (props.type !== "edit") return;
    if (!customer?.id) return;
    setFirstName(customer.firstName || "");
    setLastName(customer.lastName || "");
    setEmail(customer.email || "");
    setEmailFormat(customer.emailFormat || "");
    setIdentifier(customer.identifier || "");
    setCompany(customer.company || "");
    setSex(+customer.sex || 0);
    setGender(customer.gender || "");
    setDegree(customer.degree || "");
    setStreet(customer.street || "");
    setPostalCode(customer.postalCode || "");
    setCity(customer.city || "");
    setCountry(customer.country || "");
  }, [customer, props.type]);

  const setFirstNameHandler = (e) => {
    e.preventDefault();
    setFirstName(e.target.value);
  };

  const setLastNameHandler = (e) => {
    e.preventDefault();
    setLastName(e.target.value);
  };

  const setEmailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const setEmailFormatHandler = (e) => {
    e.preventDefault();
    setEmailFormat(e.target.value);
  };

  const setIdentifierHandler = (e) => {
    e.preventDefault();
    setIdentifier(e.target.value);
  };

  const setCompanyHandler = (e) => {
    e.preventDefault();
    setCompany(e.target.value);
  };

  const setSexToZeroHandler = (e) => {
    e.preventDefault();
    setSex(0);
  };
  const setSexToOneHandler = (e) => {
    e.preventDefault();
    setSex(1);
  };

  const setGenderHandler = (e) => {
    e.preventDefault();
    setGender(e.target.value);
  };

  const setDegreeHandler = (e) => {
    e.preventDefault();
    setDegree(e.target.value);
  };

  const setStreetHandler = (e) => {
    e.preventDefault();
    setStreet(e.target.value);
  };

  const setPostalCodeHandler = (e) => {
    e.preventDefault();
    setPostalCode(e.target.value);
  };

  const setCityHandler = (e) => {
    e.preventDefault();
    setCity(e.target.value);
  };

  const setCountryHandler = (e) => {
    e.preventDefault();
    setCountry(e.target.value);
  };

  const goBackToCustomersHandler = (e) => {
    e.preventDefault();

    navigate("/kunden");
  };

  const createCustomerHandler = (e) => {
    e.preventDefault();

    if (!isAuthenticated || !token || user.isAdmin === 0) return;

    const data = {};

    if (firstName) data.firstName = firstName;
    if (lastName) data.lastName = lastName;
    if (email) data.email = email;
    if (emailFormat) data.emailFormat = emailFormat;
    if (identifier) data.identifier = identifier;
    if (company) data.company = company;
    if (gender) data.gender = gender;
    if (degree) data.degree = degree;
    if (city) data.city = city;
    if (postalCode) data.postalCode = postalCode;
    if (street) data.street = street;
    if (country) data.country = country;

    data.sex = sex;

    if (props.type === "edit") {
      dispatch(editCustomer(customer.id, data, token, navigate, getCustomers));
    }

    if (props.type === "create") {
      dispatch(createCustomer(data, token, navigate, getCustomers));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {props.type === "create" && <Title>Neue Kunde</Title>}
        {props.type === "edit" && <Title>Kunden bearbeiten</Title>}
      </div>
      <div className={classes["inner-container"]}>
        <Input
          type={"text"}
          name={"firstName"}
          label={"Vorname"}
          value={firstName}
          onChange={setFirstNameHandler}
        />
        <Input
          type={"text"}
          name={"lastName"}
          label={"Nachname"}
          value={lastName}
          onChange={setLastNameHandler}
        />
        <Input
          type={"text"}
          name={"email"}
          label={"Email"}
          value={email}
          onChange={setEmailHandler}
        />
        <Input
          type={"text"}
          name={"emailFormat"}
          label={"Email Format"}
          value={emailFormat}
          onChange={setEmailFormatHandler}
        />
        <Input
          type={"text"}
          name={"identifier"}
          label={"Kundennummer"}
          value={identifier}
          onChange={setIdentifierHandler}
        />
        <Input
          type={"text"}
          name={"company"}
          label={"Firma"}
          value={company}
          onChange={setCompanyHandler}
        />
        <div className={classes.sex}>
          <p className={classes.label}>Geschlecht:</p>
          <CheckBoxInput
            value={sex === 0 ? true : false}
            label={"M"}
            onClick={setSexToZeroHandler}
          />
          <CheckBoxInput
            value={sex === 1 ? true : false}
            label={"W"}
            onClick={setSexToOneHandler}
          />
        </div>
        <Input
          type={"text"}
          name={"gender"}
          label={"Anrede"}
          value={gender}
          onChange={setGenderHandler}
        />
        <Input
          type={"text"}
          name={"degree"}
          label={"Akademischer Grad"}
          value={degree}
          onChange={setDegreeHandler}
        />
        <Input
          type={"text"}
          name={"street"}
          label={"Strasse"}
          value={street}
          onChange={setStreetHandler}
        />
        <Input
          type={"text"}
          name={"postalCode"}
          label={"PLZ"}
          value={postalCode}
          onChange={setPostalCodeHandler}
        />
        <Input
          type={"text"}
          name={"city"}
          label={"Stadt"}
          value={city}
          onChange={setCityHandler}
        />
        <Input
          type={"text"}
          name={"country"}
          label={"Land"}
          value={country}
          onChange={setCountryHandler}
        />
        <div className={classes.buttons}>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--white)`,
              color: `var(--c-accent-gray)`,
            }}
            onClick={goBackToCustomersHandler}
          >
            Abbrechen
          </div>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--c-primary-red)`,
              color: `var(--white)`,
            }}
            onClick={createCustomerHandler}
          >
            Speichern
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerFormContainer;
