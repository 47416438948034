import React from "react";
import { useSelector } from "react-redux";

import classes from "./HomeContainer.module.css";

// IMAGE
import img from "../../assets/images/wine-barrel.webp";

// COMPONENTS
import SigninContainer from "../auth/SigninContainer";
import WineContainer from "../wines/home-wine/WineContainer";

const HomeContainer = (props) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <div className={classes.container}>
      {!isAuthenticated && (
        <div className={classes["image-box"]}>
          <img src={img} alt={"Wine barrel"} className={classes.img} />
        </div>
      )}
      {!isAuthenticated && <SigninContainer hook={props.hook} />}
      {isAuthenticated && <WineContainer />}
    </div>
  );
};

export default HomeContainer;
