import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import classes from "./WineContainer.module.css";

// COMPONENTS
import WineCard from "./WineCard";

// IMAGE
import backgroundImage from "../../../assets/images/wine-ai.webp";

import { TiArrowSortedDown } from "react-icons/ti";

const BOTTLES_PER_SLIDE = 5;

const WineContainer = (props) => {
  const { wines } = useSelector((state) => state.wine);
  const [redirectHovered, setRedirectHovered] = useState(false);
  const [_wineRenderer, _setWineRenderer] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [numOfPages, setNumOfPages] = useState(0);
  const [fadeClass, setFadeClass] = useState(classes["fade-in"]);

  useEffect(() => {
    if (wines.length <= 0) return;
    setNumOfPages(Math.floor(wines.length / BOTTLES_PER_SLIDE));

    let start = BOTTLES_PER_SLIDE * currPage;
    let end = start + BOTTLES_PER_SLIDE;
    if (end > wines.length) end = wines.length;

    const _winesCopy = wines.map((w) => w);
    const _winesToShow = _winesCopy.slice(start, end);

    _setWineRenderer(_winesToShow.map((w) => <WineCard data={w} key={w.id} />));
  }, [wines, currPage]);

  useEffect(() => {
    const changeCurrPage = () => {
      setFadeClass(classes["fade-out"]);

      setTimeout(() => {
        if (currPage >= numOfPages) {
          setCurrPage(0);
        } else if (currPage < 0) {
          setCurrPage(0);
        } else {
          setCurrPage(currPage + 1);
        }

        setFadeClass(classes["fade-in"]);
      }, 500);
    };

    const timeout = setTimeout(changeCurrPage, 5 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [currPage, numOfPages]);

  const hovering = (e) => {
    e.preventDefault();
    setRedirectHovered(true);
  };

  const notHovering = (e) => {
    e.preventDefault();
    setRedirectHovered(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes["image-box"]}>
        <img
          className={classes.image}
          src={backgroundImage}
          alt={`Wine glasses`}
        />
      </div>
      <div className={`${fadeClass} ${classes["wines-container"]}`}>
        {_wineRenderer}
      </div>
      <Link
        to={`/weine`}
        className={classes.redirect}
        onMouseEnter={hovering}
        onMouseLeave={notHovering}
      >
        <TiArrowSortedDown
          className={classes.icon}
          style={
            redirectHovered
              ? { color: `var(--c-accent-gray)` }
              : { color: `var(--c-primary-red)` }
          }
        />
      </Link>
    </div>
  );
};

export default WineContainer;
