import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import handleError from "../../helper/handleError";

let URL;

URL = process.env.REACT_APP_SERVER_URL;

const initialWineState = {
  errorWine: false,
  messageWine: false,
  wines: [],
  wine: {},
  totalNumOfWines: 0,
};

const wineSlice = createSlice({
  name: "wine",
  initialState: initialWineState,
  reducers: {
    setErrorWine(state, action) {
      state.errorWine = action.payload.value;
    },
    setMessageWine(state, action) {
      state.messageWine = action.payload.value;
    },
    setWines(state, action) {
      state.wines = action.payload.data.wines;
      state.totalNumOfWines = action.payload.data.totalNumOfWines;
    },
    setWine(state, action) {
      state.wine = action.payload.data;
    },
    updateWinesQuantity(state, action) {
      const _wines = state.wines.map((w) => {
        if (w.id === action.payload.data.wineId) {
          const _copyOfW = { ...w };
          _copyOfW.totalOrders =
            _copyOfW.totalOrders - action.payload.data.quantity;
          return _copyOfW;
        } else {
          return w;
        }
      });

      state.wines = _wines;
    },
    setTotalNumOfWines(state, action) {
      state.totalNumOfWines = action.payload.value;
    },
  },
});

export const getWines = () => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "get",
        url: `${URL}/wines/get-wines`,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          wineSlice.actions.setWines({
            data: {
              wines: response.data.data.wines,
              length: response.data.data.totalNumOfWines,
            },
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(err, null, dispatch, wineSlice.actions.setErrorWine);
    }
  };
};

export const getWine = (wineId) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "get",
        url: `${URL}/wines/${wineId}`,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          wineSlice.actions.setWine({
            data: response.data.data,
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(err, null, dispatch, wineSlice.actions.setErrorWine);
    }
  };
};

export const deleteWine = (wineId, token, getWinesFN) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "delete",
        url: `${URL}/wines/${wineId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(getWinesFN());
        dispatch(
          wineSlice.actions.setMessageWine({
            value: "Wein erfolgreich gelöscht.",
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(
        err,
        `An error occurred while deleting the wine.`,
        dispatch,
        wineSlice.actions.setErrorWine
      );
    }
  };
};

export const createWine = (userInputs, token, navigate, getWinesFN) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "post",
        url: `${URL}/wines`,
        data: userInputs,
        headers: {
          "Content-Type": "multypart/form-data",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          wineSlice.actions.setWine({
            data: response.data.data,
          })
        );

        navigate(`/weine/${response.data.data.id}`);
        dispatch(getWinesFN());

        dispatch(
          wineSlice.actions.setMessageWine({
            value: "Wein erfolgreich kreiert.",
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(
        err,
        `An error occurred while creating the wine.`,
        dispatch,
        wineSlice.actions.setErrorWine
      );
    }
  };
};

export const editWine = (userInputs, wineId, token, navigate, getWinesFN) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "patch",
        url: `${URL}/wines/${wineId}`,
        data: userInputs,
        headers: {
          "Content-Type": "multypart/form-data",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          wineSlice.actions.setWine({
            data: response.data.data,
          })
        );

        navigate(`/weine/${response.data.data.id}`);
        dispatch(getWinesFN());
        dispatch(
          wineSlice.actions.setMessageWine({
            value: "Wein erfolgreich aktulisiert.",
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(
        err,
        `An error occurred while editing the wine.`,
        dispatch,
        wineSlice.actions.setErrorWine
      );
    }
  };
};

export default wineSlice;
