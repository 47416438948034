import { configureStore } from "@reduxjs/toolkit";

// REDUCERS
import alertSlice from "./reducers/alert-slice";
import authSlice from "./reducers/auth-slice";
import wineSlice from "./reducers/wine-slice";
import orderSlice from "./reducers/order-slice";
import shareSlice from "./reducers/share-slice";
import customerSlice from "./reducers/customer-slice";

const store = configureStore({
  reducer: {
    alert: alertSlice.reducer,
    auth: authSlice.reducer,
    wine: wineSlice.reducer,
    order: orderSlice.reducer,
    share: shareSlice.reducer,
    customer: customerSlice.reducer,
  },
});

export const alertActions = alertSlice.actions;
export const authActions = authSlice.actions;
export const wineActions = wineSlice.actions;
export const orderActions = orderSlice.actions;
export const shareActions = shareSlice.actions;
export const customerActions = customerSlice.actions;

export default store;
