import React from "react";

import classes from "./Message.module.css";

const Message = (props) => {
  return (
    props.data.message && (
      <div className={classes.container}>{props.data.message}</div>
    )
  );
};

export default Message;
