const handleError = (err, msg, dispatch, setError) => {
  if (process.env.NODE_ENV !== "production") {
    if (!msg) return;
    dispatch(setError({ value: msg }));
  } else {
    if (!msg) return;
    dispatch(setError({ value: msg }));
    //console.error(err);
  }
};

export default handleError;
