import React from "react";

import classes from "./SingleTableHeader.module.css";

import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";

const SingleTableHeader = (props) => {
  const sortTableHandler = (e) => {
    e.preventDefault();

    props.hook.setSortHandler(props.name);
  };

  const isSorting = props.hook.sort.field === props.name;

  return (
    <div className={classes["table-header"]} onClick={sortTableHandler}>
      {props.label}
      <div className={classes["table-sort-btns"]}>
        <IoMdArrowDropup
          className={classes.icon}
          style={
            isSorting && props.hook.sort.by === "desc"
              ? { color: "var(--white)" }
              : {}
          }
        />
        <IoMdArrowDropdown
          className={classes.icon}
          style={
            isSorting && props.hook.sort.by === "asc"
              ? { color: "var(--white)" }
              : {}
          }
        />
      </div>
    </div>
  );
};

export default SingleTableHeader;
