import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import classes from "./FormWineContainer.module.css";

// COMPONENTS
import Title from "../../UI/Title";
import WineInput from "./WineInput";

// STORE
import {
  createWine,
  getWines,
  getWine,
  editWine,
} from "../../../store/reducers/wine-slice";

import { BsImages } from "react-icons/bs";

const WINE_IMAGE_URL_PATH = process.env.REACT_APP_WINE_IMAGE_URL_PATH;

const FormWineContainer = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, token, user } = useSelector((state) => state.auth);
  const { wine } = useSelector((state) => state.wine);

  const [wineName, setWineName] = useState("");
  const [shares, setShares] = useState("");
  const [additionalPayment, setAdditionalPayment] = useState("");
  const [stockCount, setStockCount] = useState("");

  const [oldImage, setOldImage] = useState("");
  const [imageForUpload, setImageForUpload] = useState();
  const [imagePreviewURL, setImagePreviewURL] = useState();

  useEffect(() => {
    if (props.type === "edit" && params.wineId) {
      dispatch(getWine(params.wineId));
    }
  }, [dispatch, props.type, params.wineId]);

  useEffect(() => {
    if (props.type !== "edit") return;
    if (!wine.id) return;

    setWineName(wine.wineName || "");
    setShares(wine.shares || "");
    setAdditionalPayment(wine.additionalPayment || "");
    setStockCount(wine.stockCount || "");
    setOldImage(wine.image || "");
  }, [wine, props.type]);

  const setImage = (e) => {
    e.preventDefault();

    if (e.target.files && e.target.files.length === 1) {
      let file = e.target.files[0];
      setImageForUpload(file);
      return;
    }
  };

  useEffect(() => {
    if (!imageForUpload) return;

    const fileReader = new FileReader();

    fileReader.onload = () => {
      setImagePreviewURL(fileReader.result);
    };

    fileReader.readAsDataURL(imageForUpload);
  }, [imageForUpload]);

  const changeTitleHandler = (e) => {
    e.preventDefault();
    setWineName(e.target.value);
  };
  const changeSharesHandler = (e) => {
    e.preventDefault();
    setShares(e.target.value);
  };
  const changeAddPaymentHandler = (e) => {
    e.preventDefault();
    setAdditionalPayment(e.target.value);
  };
  const changeStockCountHandler = (e) => {
    e.preventDefault();
    setStockCount(e.target.value);
  };

  const navigateToWinesHandler = (e) => {
    e.preventDefault();
    navigate("/weine");
  };

  const createWineHandler = (e) => {
    e.preventDefault();

    if (!isAuthenticated || !token || user.isAdmin === 0) return;

    if (!wineName || !shares || !additionalPayment || !stockCount) return;

    const formData = new FormData();

    if (props.type === "edit" && imageForUpload) {
      formData.append("oldImage", oldImage);
    }

    formData.append("image", imageForUpload || "");
    formData.append("wineName", wineName);
    formData.append("shares", shares);
    formData.append("additionalPayment", additionalPayment);
    formData.append("stockCount", stockCount);

    if (props.type === "edit") {
      dispatch(editWine(formData, wine.id, token, navigate, getWines));
    }
    if (props.type === "create") {
      dispatch(createWine(formData, token, navigate, getWines));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {props.type === "create" && <Title>Neuer Wein</Title>}
        {props.type === "edit" && <Title>Bearbeiten Weine</Title>}
      </div>
      <div className={classes["inner-container"]}>
        <div className={classes["create__article-image"]}>
          <label
            className={classes["create__article-image-label"]}
            htmlFor="image"
          >
            <BsImages className={classes["create__article-image-icon"]} />
            <p>Bild hinzufügen</p>
          </label>
          <input
            className={classes["create__article-image-input"]}
            type="file"
            name="image"
            id="image"
            multiple={false}
            accept=".jpg,.jpeg,.png"
            onChange={setImage}
          />
          <div className={classes["create__article-image-container"]}>
            {oldImage && !imagePreviewURL && (
              <img
                src={`${WINE_IMAGE_URL_PATH}/${oldImage}`}
                alt={`${wine.wineName}`}
              />
            )}
            {imagePreviewURL && (
              <img src={imagePreviewURL} alt={`Preview of upload`} />
            )}
          </div>
        </div>
        <WineInput
          type={"text"}
          name={"wineName"}
          label={"Weine name"}
          value={wineName}
          onChange={changeTitleHandler}
        />
        <WineInput
          type={"number"}
          name={"shares"}
          label={"Aktien Anzahl"}
          value={shares}
          onChange={changeSharesHandler}
        />
        <WineInput
          type={"number"}
          name={"additionalPayment"}
          label={"Zuzahlung (€)"}
          value={additionalPayment}
          onChange={changeAddPaymentHandler}
        />
        <WineInput
          type={"number"}
          name={"stockCount"}
          label={"Lagerbestand"}
          value={stockCount}
          onChange={changeStockCountHandler}
        />
        <div className={classes.buttons}>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--white)`,
              color: `var(--c-accent-gray)`,
            }}
            onClick={navigateToWinesHandler}
          >
            Abbrechen
          </div>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--c-primary-red)`,
              color: `var(--white)`,
            }}
            onClick={createWineHandler}
          >
            Speichern
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormWineContainer;
