import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import classes from "./OrdersContainer.module.css";

// COMPONENTS
import OrdersAdmin from "./OrdersAdmin";
import Title from "../UI/Title";
import AddNewBtn from "../UI/AddNewBtn";
import DownloadExcelBtn from "../UI/DownloadExcelBtn";

const generateYears = () => {
  const years = [];
  const startingYear = 2014;
  const addYears = 20;
  for (let i = 0; i <= addYears; i++) {
    years.push(startingYear + i);
  }

  return years;
};

const OrdersContainer = (props) => {
  const navigate = useNavigate();
  const { user, year } = useSelector((state) => state.auth);
  const { orders } = useSelector((state) => state.order);

  const [haveUnconfirmed, setHaveUnconfirmed] = useState(false);

  useEffect(() => {
    const _haveUnconfirmed = orders.some((order) => order.isDone === 0);
    setHaveUnconfirmed(_haveUnconfirmed);
  }, [orders]);

  const redirectToCreateOrderHandler = (e) => {
    e.preventDefault();
    navigate("schaffen");
  };

  const confirmAllOrders = (e) => {
    e.preventDefault();
    navigate("absenden");
  };

  const changeYearHandler = (e) => {
    e.preventDefault();

    props.hook.setYearInputHandler(+e.target.value);
  };

  const _years = generateYears();

  const _renderYearsOptions = _years.map((y, i) => (
    <option key={i} value={y}>
      {y}
    </option>
  ));

  useEffect(() => {
    if (props.hook.yearInput) return;
    props.hook.setYearInputHandler(year);
  }, [props.hook, year]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Title>Bestellungen</Title>
        {user.isAdmin === 0 && haveUnconfirmed && (
          <div className={classes["header-btns"]}>
            <AddNewBtn
              onClickHandler={confirmAllOrders}
              classOverride={classes["confirm-orders"]}
            >
              Bestellung schliessen und absenden
            </AddNewBtn>
          </div>
        )}
        {user.isAdmin === 1 && (
          <div className={classes["header-btns"]}>
            <select
              onChange={changeYearHandler}
              value={props.hook.yearInput || new Date().getFullYear()}
              className={classes.select}
            >
              {_renderYearsOptions}
            </select>
            <DownloadExcelBtn model={"orders"} fileName={"bestellung"} />
            <AddNewBtn onClickHandler={redirectToCreateOrderHandler}>
              Neue Bestellung
            </AddNewBtn>
          </div>
        )}
      </div>
      <OrdersAdmin hook={props.hook} />
    </div>
  );
};

export default OrdersContainer;
