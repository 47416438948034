import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

import classes from "./OrderFormContainer.module.css";

// COMPONENTS
import Title from "../../UI/Title";
import Input from "../../UI/Input";
import CheckBoxInput from "../../UI/CheckBoxInput";

// STORE
import {
  getOrder,
  getOrders,
  createOrder,
  createOrderAdmin,
  editOrder,
} from "../../../store/reducers/order-slice";
import {
  getCustomer,
  getCustomers,
} from "../../../store/reducers/customer-slice";
import { getWines } from "../../../store/reducers/wine-slice";
import {
  customerActions,
  wineActions,
  authActions,
} from "../../../store/redux-store";

const OrderFormContainer = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.order);
  const { customers, customer } = useSelector((state) => state.customer);
  const { wines } = useSelector((state) => state.wine);
  const { isAuthenticated, token, user } = useSelector((state) => state.auth);

  // LISTS
  const [_customers, _setCustomers] = useState([]);
  const [_wines, _setWines] = useState([]);

  // INPUTS
  const [customerId, setCustomerId] = useState(1);
  const [totalShares, setTotalShares] = useState("");
  const [availableShares, setAvailableShares] = useState("");
  const [wineId, setWineId] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [pickup, setPickup] = useState(0);

  // GET CUSTOMERS AND WINES ON LOAD
  useEffect(() => {
    if (!isAuthenticated || !token || user.isAdmin === 0) return;
    dispatch(getCustomers(token));
    dispatch(getWines());

    return () => {
      dispatch(
        customerActions.setCustomers({
          data: { customers: [], totalNumOfCustomers: 0 },
        })
      );
      dispatch(
        wineActions.setWines({ data: { wines: [], totalNumOfWines: 0 } })
      );
      dispatch(customerActions.setCustomer({ data: {} }));
    };
  }, [dispatch, token, isAuthenticated, user.isAdmin]);

  useEffect(() => {
    if (props.type !== "edit") return;
    if (!isAuthenticated || !token) return;

    if (order?.id && order?.id === +params.orderId) return;
    dispatch(getOrder(params.orderId, token));
  }, [
    dispatch,
    isAuthenticated,
    token,
    user.isAdmin,
    props.type,
    order,
    params.orderId,
  ]);

  useEffect(() => {
    if (props.type !== "edit") return;
    if (!order.id) return;

    if (user.isAdmin === 1) {
      setCustomerId(order.customerId);
    } else {
      setCustomerId(user.customerId);
    }

    setWineId(order.wineId);
    setQuantity(order.quantity);
    setPickup(order.pickup);
  }, [props.type, order, user.isAdmin, user.customerId]);

  // SET LIST OF CUSTOMERS FOR RENDERER
  useEffect(() => {
    // CUSTOMERS
    const _customersList = customers.map((c) => {
      return (
        <option
          key={c.id}
          className={classes.option}
          value={c.id}
          onClick={setCustomerIdHandler}
        >{`${c.email} - ${c.firstName} ${c.lastName}`}</option>
      );
    });

    // WINES
    const _winesList = wines.map((w) => {
      return (
        <option
          key={w.id}
          className={classes.option}
          value={w.id}
          onClick={setWineIdHandler}
        >{`${w.wineName} - [AktienAnzahl: ${w.shares} - Zuzahlung pro Flasche: ${w.additionalPayment} €]`}</option>
      );
    });

    _setCustomers(_customersList);
    _setWines(_winesList);

    if (props.type === "create" && customers.length > 0 && wines.length > 0) {
      setCustomerId(customers[0]?.id);
      setWineId(wines[0]?.id);
    }
  }, [customers, props.type, wines]);

  // GET CUSTOMER SHARES WHEN CUSTOMERID CHANGES
  useEffect(() => {
    if (!token || !isAuthenticated || customer.id === customerId) return;
    dispatch(getCustomer(customerId, token));
  }, [dispatch, customerId, isAuthenticated, token, customer]);

  const setCustomerIdHandler = (e) => {
    e.preventDefault();
    setCustomerId(+e.target.value);
  };

  const setWineIdHandler = (e) => {
    e.preventDefault();
    setWineId(+e.target.value);
  };

  // QUANTITY
  const setQuantityHandler = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setQuantity("");
    } else {
      setQuantity(+e.target.value);
    }
  };

  const onBlurQuantityHandler = (value) => {
    if (value === "" || !value) {
      setQuantity(1);
    } else {
      setQuantity(+value);
    }
  };

  // PICKUP
  const setPickupToOneHandler = (e) => {
    e.preventDefault();
    setPickup(1);
  };
  const setPickupToZeroHandler = (e) => {
    e.preventDefault();
    setPickup(0);
  };

  // RETURN
  const goBackToOrdersHandler = (e) => {
    e.preventDefault();
    navigate("/bestellung");
  };

  // CREATE ORDER
  const createOrderHandler = (e) => {
    e.preventDefault();

    const pickedWine = wines.find((w) => w.id === wineId);
    if (props.type === "create") {
      const haveEnoughShares =
        customer.shares.availableShares > quantity * pickedWine.shares;

      if (!haveEnoughShares) {
        console.log("there is not enough shares");
        return;
      }

      if (pickedWine.stockCount < quantity) {
        console.log("There is not enough wines in stock!");
        return;
      }

      const data = {};
      data.customerId = customerId;
      data.wineId = wineId;
      data.quantity = quantity;
      data.pickup = pickup;

      dispatch(createOrderAdmin(data, token, navigate, getOrders));
    }

    if (props.type === "edit") {
      const originalWine = wines.find((w) => w.id === order.wineId);

      const _wineStockWithoutOrder =
        pickedWine.stockCount - +pickedWine.totalOrders + order.quantity;
      const availableWithoutOrderShares =
        customer.shares.availableShares + order.quantity * originalWine.shares;
      const sharesUsedWithoutOrder =
        customer.shares.sharesUsed - order.quantity * originalWine.shares;

      if (availableWithoutOrderShares < quantity * pickedWine.shares) {
        console.log("you dont have enough shares");
        return;
      }

      if (order.wineId === wineId && _wineStockWithoutOrder < quantity) {
        console.log("there is not enough wine in stock");
        return;
      }
      if (
        order.wineId !== wineId &&
        pickedWine.stockCount - +pickedWine.totalOrders < quantity
      ) {
        console.log("there is not enough wine in stock");
        return;
      }

      const data = {};
      data.customerId = customerId;
      data.wineId = wineId;
      data.quantity = quantity;
      data.pickup = pickup;

      const editUsersShares = () => {
        const _userData = { ...user };
        const _numOfShares = quantity * pickedWine.shares;
        _userData.shares = {
          totalNumOfShares: user.shares.totalNumOfShares,
          sharesUsed: sharesUsedWithoutOrder + _numOfShares,
          availableShares: availableWithoutOrderShares - _numOfShares,
        };

        let _totalOrders = 0;

        if (wineId === order.wineId) {
          _totalOrders = order.quantity - quantity;
          dispatch(
            wineActions.updateWinesQuantity({
              data: {
                wineId: wineId,
                quantity: _totalOrders,
              },
            })
          );
        } else {
          _totalOrders = quantity;
          dispatch(
            wineActions.updateWinesQuantity({
              data: {
                wineId: wineId,
                quantity: -_totalOrders,
              },
            })
          );
          dispatch(
            wineActions.updateWinesQuantity({
              data: {
                wineId: order.wineId,
                quantity: _totalOrders,
              },
            })
          );
        }
        // wineActions.updateWinesQuantity({
        //   data: {
        //     wineId: wineId,
        //     totalOrders: +pickedWine.totalOrders + quantity,
        //   },
        // });

        dispatch(authActions.setUser({ data: _userData }));
      };

      dispatch(
        editOrder(
          params.orderId,
          data,
          token,
          navigate,
          getOrders,
          editUsersShares
        )
      );
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {props.type === "create" && <Title>Neue Aktien</Title>}
        {props.type === "edit" && <Title>Bearbeiten Aktien</Title>}
      </div>
      <div className={classes["inner-container"]}>
        <h5>Kunde:</h5>
        {user.isAdmin === 1 ? (
          <select
            value={customerId}
            className={classes.select}
            onChange={setCustomerIdHandler}
          >
            {_customers}
          </select>
        ) : (
          <div className={classes.user}>{`${user.username}`}</div>
        )}
        {user.isAdmin === 1 ? (
          <div className={classes["shares"]}>
            <div className={classes.share}>
              <p className={classes.label}>{"AktienAnzahl vom Kunden:"}</p>
              <p className={classes.value}>
                {customer.id ? customer.shares.totalNumOfShares : 0}
              </p>
            </div>
            <div className={classes.share}>
              <p className={classes.label}>{"Der verbleibende Bonus:"}</p>
              <p className={classes.value}>
                {customer.id ? customer.shares.availableShares : 0}
              </p>
            </div>
          </div>
        ) : (
          <div className={classes["shares"]}>
            <div className={classes.share}>
              <p className={classes.label}>{"AktienAnzahl vom Kunden:"}</p>
              <p className={classes.value}>
                {user.id ? user.shares.totalNumOfShares : 0}
              </p>
            </div>
            <div className={classes.share}>
              <p className={classes.label}>{"Der verbleibende Bonus:"}</p>
              <p className={classes.value}>
                {user.id ? user.shares.availableShares : 0}
              </p>
            </div>
          </div>
        )}
        <h5>Weine:</h5>
        <select
          value={wineId}
          className={classes.select}
          onChange={setWineIdHandler}
        >
          {_wines}
        </select>
        <div className={classes["shares"]}>
          <div className={classes.share}>
            <p className={classes.label}>{"Lagerbestand:"}</p>
            <p className={classes.value}>
              {wines.length > 0
                ? `${
                    wines.find((w) => w.id === wineId)?.stockCount -
                    +wines.find((w) => w.id === wineId)?.totalOrders
                  }`
                : 0}
            </p>
          </div>
        </div>
        <h5>Bestellung:</h5>
        <Input
          type={"number"}
          name={"quantity"}
          label={"Menge"}
          value={quantity}
          onChange={setQuantityHandler}
          onBlur={onBlurQuantityHandler}
        />
        <div className={classes.pickup}>
          {/* <p className={classes.label}>Geschlecht:</p> */}
          <CheckBoxInput
            value={pickup === 0 ? true : false}
            label={"Lieferung an den Wohnort"}
            onClick={setPickupToZeroHandler}
          />
          <CheckBoxInput
            value={pickup === 1 ? true : false}
            label={"Abholung in Bodega"}
            onClick={setPickupToOneHandler}
          />
        </div>
        <div className={classes.buttons}>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--white)`,
              color: `var(--c-accent-gray)`,
            }}
            onClick={goBackToOrdersHandler}
          >
            Abbrechen
          </div>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--c-primary-red)`,
              color: `var(--white)`,
            }}
            onClick={createOrderHandler}
          >
            Speichern
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderFormContainer;
