import React from "react";

import classes from "./OrderFilters.module.css";

// COMPONENTS
import FilterInput from "./FilterInput";

import { BsFilterRight } from "react-icons/bs";

const OrderFilters = (props) => {
  const filterOrdersHandler = (e) => {
    e.preventDefault();
  };

  const changePickupHandler = (e) => {
    e.preventDefault();

    let value = "";
    if (e.target.value !== "") value = +e.target.value;
    props.hook.setFiltersHandler({ pickup: value });
  };

  const changeIsDoneHandler = (e) => {
    e.preventDefault();

    let value = "";
    if (e.target.value !== "") value = +e.target.value;
    props.hook.setFiltersHandler({ isDone: value });
  };

  return (
    <form className={classes.container} onSubmit={filterOrdersHandler}>
      <FilterInput
        placeholder={"Kunde"}
        type="text"
        name="fullName"
        hook={props.hook}
        sortHook={props.sortHook}
      />
      <FilterInput
        placeholder={"Email"}
        type="text"
        name="email"
        hook={props.hook}
        sortHook={props.sortHook}
      />
      <FilterInput
        placeholder={"Adresse"}
        type="text"
        name="street"
        hook={props.hook}
        sortHook={props.sortHook}
      />
      <FilterInput
        placeholder={"Stadt"}
        type="text"
        name="city"
        hook={props.hook}
        sortHook={props.sortHook}
      />
      <FilterInput
        placeholder={"Land"}
        type="text"
        name="country"
        hook={props.hook}
        sortHook={props.sortHook}
      />
      <FilterInput
        placeholder={"Wein"}
        type="text"
        name="wineName"
        hook={props.hook}
        sortHook={props.sortHook}
      />
      <FilterInput
        hide={true}
        placeholder={"Menge"}
        type="number"
        name="quantity"
        hook={props.hook}
        sortHook={props.sortHook}
      />
      <FilterInput
        hide={true}
        placeholder={"ZpF"}
        type="number"
        name="additionalPayment"
        hook={props.hook}
        sortHook={props.sortHook}
      />
      <FilterInput
        hide={true}
        placeholder={"Zuzahlung I."}
        type="number"
        name="totalPayment"
        hook={props.hook}
        sortHook={props.sortHook}
      />
      <select
        value={props.hook.filters.pickup}
        className={classes.dropdown}
        onChange={changePickupHandler}
      >
        <option value={""}>Alle</option>
        <option value={0}>Lieferung an den Wohnort</option>
        <option value={1}>Abholung in Bodega</option>
      </select>
      <select
        value={props.hook.filters.isDone}
        className={classes.dropdown}
        onChange={changeIsDoneHandler}
      >
        <option value={""}>Alle</option>
        <option value={0}>Nein</option>
        <option value={1}>Ja</option>
      </select>
      {/* <FilterInput
        hide={true}
        placeholder={"Versand"}
        type="number"
        name="pickup"
        hook={props.hook}
        sortHook={props.sortHook}
      />
      <FilterInput
        hide={true}
        placeholder={"Abg"}
        type="number"
        name="isDone"
        hook={props.hook}
        sortHook={props.sortHook}
      /> */}
      <FilterInput
        hide={true}
        placeholder={"Datum"}
        type="text"
        name="createdAt"
        hook={props.hook}
        sortHook={props.sortHook}
      />
      <div className={classes.button}>
        <p>Filters</p>
        <BsFilterRight />
      </div>
    </form>
  );
};

export default OrderFilters;
