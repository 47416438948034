import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import classes from "./WinesContainer.module.css";

// COMPONENTS
import Title from "../UI/Title";
import WineCard from "./WineCard";
import AddNewBtn from "../UI/AddNewBtn";
import DownloadExcelBtn from "../UI/DownloadExcelBtn";
import OrderCard from "./OrderCard";

// IMAGES
import bgImg from "../../assets/images/vines4.png";

const WinesContainer = (props) => {
  const navigate = useNavigate();
  const { wines } = useSelector((state) => state.wine);
  const { user } = useSelector((state) => state.auth);
  const { newOrders } = useSelector((state) => state.order);

  const _winesRenderer = wines.map((w) => {
    return <WineCard key={w.id} data={w} />;
  });

  const redirectToCreateWine = (e) => {
    e.preventDefault();
    navigate("schaffen");
  };

  return (
    <div className={classes.container}>
      <div className={classes.background}>
        <img src={bgImg} alt={"vines"} />
      </div>
      <div className={classes.header}>
        <Title>Weine</Title>
        {user.isAdmin === 1 && (
          <div className={classes["header-btns"]}>
            <DownloadExcelBtn model={"wines"} fileName={"weine"} />
            <AddNewBtn onClickHandler={redirectToCreateWine}>
              Neuer Wein
            </AddNewBtn>
          </div>
        )}
        {user.isAdmin === 0 && (
          <div className={classes["customer-share__info"]}>
            <p>{`Sie haben ${user.shares.availableShares}
               Aktien zur Verfügung`}</p>
          </div>
        )}
      </div>
      <div className={classes["wines-container"]}>{_winesRenderer}</div>
      <div className={classes.separator} style={{ width: `60%` }} />
      {user.isAdmin === 0 && newOrders.length > 0 && <OrderCard />}
      {/* <OrderCard /> */}
    </div>
  );
};

export default WinesContainer;
