import React from "react";
import { Routes, Route } from "react-router-dom";

// COMPONENTS
import SharesContainer from "../components/shares/SharesContainer";
import SingleShareContainer from "../components/shares/single-share/SingleShareContainer";
import ShareFormContainer from "../components/shares/share-form/ShareFormContainer";

// CUSTOM HOOKS
import useSharesFilter from "../custom-hooks/useSharesFilter";

const SharesRoutes = () => {
  const sharesHook = useSharesFilter();
  return (
    <Routes>
      <Route path="/" element={<SharesContainer hook={sharesHook} />} />
      <Route path="/:shareId" element={<SingleShareContainer />} />
      <Route
        path="/schaffen"
        element={<ShareFormContainer type={"create"} />}
      />
      <Route
        path="/redigieren/:shareId"
        element={<ShareFormContainer type={"edit"} />}
      />
    </Routes>
  );
};

export default SharesRoutes;
