import React, { useEffect } from "react";

import classes from "./OrdersAdmin.module.css";

// COMPONENTS
import OrderTable from "./OrderTable";
import OrderFilters from "./OrderFilters";

// CUSTOM HOOKS
import useOrders from "../../custom-hooks/useOrders";

const OrdersAdmin = (props) => {
  const sortHook = useOrders();

  return (
    <div className={classes.container}>
      <OrderFilters hook={props.hook} sortHook={sortHook} />
      <OrderTable hook={props.hook} sortHook={sortHook} />
    </div>
  );
};

export default OrdersAdmin;
