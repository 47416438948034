import React from "react";

import classes from "./CustomersFilters.module.css";

// COMPONENTS
import FilterInput from "../orders/FilterInput";

import { BsFilterRight } from "react-icons/bs";

const filterCustomersHandler = (e) => {
  e.preventDefault();
};

const CustomersFilters = (props) => {
  return (
    <form className={classes.container} onSubmit={filterCustomersHandler}>
      <FilterInput
        placeholder={"Vorname"}
        type="text"
        name="firstName"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Nachname"}
        type="text"
        name="lastName"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Email"}
        type="text"
        name="email"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Geburtsdatum"}
        type="text"
        name="birthday"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Telefonnummer"}
        type="text"
        name="phoneNumber"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Telefonnummer G."}
        type="text"
        name="privateNumber"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Adresse"}
        type="text"
        name="street"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Stadt"}
        type="text"
        name="city"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Land"}
        type="text"
        name="country"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Identifikationzeichenkette"}
        type="text"
        name="identificationString"
        hook={props.hook}
      />
      <FilterInput
        hide={true}
        placeholder={"Bestellungen"}
        type="text"
        name="numberOfOrders"
        hook={props.hook}
      />
      <div className={classes.button}>
        <p>Filters</p>
        <BsFilterRight />
      </div>
    </form>
  );
};

export default CustomersFilters;
