import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

// STORE
import { createOrder, confirmOrders } from "../store/reducers/order-slice";
import { authActions, wineActions } from "../store/redux-store";

const useOrders = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user, token, year } = useSelector(
    (state) => state.auth
  );
  const { newOrders, orders } = useSelector((state) => state.order);

  const [sort, setSort] = useState({
    field: "",
    by: "",
  });

  const setSortHandler = (field) => {
    if (sort.field === field) {
      if (!sort.by) {
        setSort({
          field: sort.field,
          by: "asc",
        });
        return;
      }
      const _by = sort.by === "asc" ? "desc" : "asc";
      setSort({
        field: sort.field,
        by: _by,
      });
    } else {
      setSort({
        field: field,
        by: "asc",
      });
    }
  };

  const createOrderHandler = (e) => {
    // e.preventDefault();

    if (!isAuthenticated || !token) return;

    newOrders.forEach((order) => {
      dispatch(createOrder(order, token, wineActions, authActions));
    });
  };

  const confirmAllOrders = (e) => {
    if (!isAuthenticated || !token) return;
    const _ordersForConfirmation = orders.filter((o) => o.isDone === 0);

    dispatch(confirmOrders(_ordersForConfirmation, token, navigate));
  };
  return {
    createOrderHandler,
    confirmAllOrders,
    setSortHandler,
    sort,
  };
};

export default useOrders;
