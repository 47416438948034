import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

// STORE

import classes from "./ConfirmOrders.module.css";

// COMPONENTS
import Title from "../../UI/Title";
import OrderCard from "./OrderCard";

// CUSTOM HOOKS
import useOrders from "../../../custom-hooks/useOrders";

const ConfirmOrders = (props) => {
  const navigate = useNavigate();
  const { isAuthenticated, token } = useSelector((state) => state.auth);
  const { orders } = useSelector((state) => state.order);
  const orderHook = useOrders();

  const [unconfirmedOrders, setUnconfirmedOrders] = useState([]);
  const [_ordersRenderer, _setOrdersRenderer] = useState([]);

  useEffect(() => {
    const _unconfOrders = orders.filter((o) => o.isDone === 0);
    const _listRenderer = _unconfOrders.map((o) => (
      <OrderCard key={o.id} data={o} />
    ));
    setUnconfirmedOrders(_unconfOrders);
    _setOrdersRenderer(_listRenderer);
  }, [orders]);

  const goBackToOrdersHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const confirmOrdersHandler = (e) => {
    e.preventDefault();
    orderHook.confirmAllOrders();
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Title>Bestellung</Title>
      </div>
      <div className={classes["inner-container"]}>
        {_ordersRenderer}
        <p className={classes.question}>
          Sind Sie sicher, dass Sie die Bestellung schliessen und absenden
          möchten?
        </p>
        <div className={classes.buttons}>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--white)`,
              color: `var(--c-accent-gray)`,
            }}
            onClick={goBackToOrdersHandler}
          >
            Nein
          </div>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--c-primary-red)`,
              color: `var(--white)`,
            }}
            onClick={confirmOrdersHandler}
          >
            Ja
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOrders;
