import React from "react";

import classes from "./CustomerSettings.module.css";

// COMPONENTS
import CustomerSettingsContainer from "../components/customers/settings/CustomerSettingsContainer";

const CustomerSettings = (props) => {
  return (
    <div className={classes.container}>
      <CustomerSettingsContainer />
    </div>
  );
};

export default CustomerSettings;
