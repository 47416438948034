import React from "react";
import { Routes, Route } from "react-router-dom";

// COMPONENTS
import CustomersContainer from "../components/customers/CustomersContainer";
import SingleCustomerContainer from "../components/customers/single-customer/SingleCustomerContainer";
import CustomerFormContainer from "../components/customers/customer-form/CustomerFormContainer";

// CUSTOM HOOKS
import useCustomersFilter from "../custom-hooks/useCustomersFilters";

const CustomersRoutes = () => {
  const customersHook = useCustomersFilter();

  return (
    <Routes>
      <Route path="/" element={<CustomersContainer hook={customersHook} />} />
      <Route path="/:customerId" element={<SingleCustomerContainer />} />
      <Route
        path="/schaffen"
        element={<CustomerFormContainer type={"create"} />}
      />
      <Route
        path="/redigieren/:customerId"
        element={<CustomerFormContainer type={"edit"} />}
      />
      {/* <Route path="/schaffen" element={<FormWineContainer type={"create"} />} />
      <Route
        path="/redigieren/:wineId"
        element={<FormWineContainer type={"edit"} />}
      /> */}
    </Routes>
  );
};

export default CustomersRoutes;
