import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

// STORE
import { getMyData, signin } from "../store/reducers/auth-slice";
import { authActions } from "../store/redux-store";

const useAuth = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, token, isAuthenticated } = useSelector((store) => store.auth);

  const [usernameInput, setUsernameInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    const _token = localStorage.getItem("token");
    if (_token && _token !== "undefined") {
      dispatch(getMyData(JSON.parse(_token)));
    }
  }, [dispatch]);

  const checkTextInput = (value, setError, options) => {
    let isValid = true;
    if (options.min) {
      let isShort = false;
      isShort = value.length < options.min;
      if (isShort) {
        setError("Username is too short!");
        isValid = false;
        return isValid;
      }
    }

    if (isValid) setError("");
    return isValid;
  };

  // USERNAME
  const setUsernameHandler = (e) => {
    e.preventDefault();
    setUsernameInput(e.target.value);
  };

  const checkUsernameHandler = (e) => {
    e.preventDefault();
    checkTextInput(e.target.value, setUsernameError, { min: 3 });
  };

  // PASSWORD
  const setPasswordHandler = (e) => {
    e.preventDefault();
    setPasswordInput(e.target.value);
  };

  const checkPasswordHandler = (e) => {
    e.preventDefault();
    checkTextInput(e.target.value, setPasswordError, { min: 4 });
  };

  const onSubmitSigninHandler = (e) => {
    e.preventDefault();
    if (isAuthenticated) return;

    if (usernameError || passwordError) return;

    dispatch(signin({ username: usernameInput, password: passwordInput }));
  };

  const onLogoutHandler = (e) => {
    e.preventDefault();

    dispatch(authActions.logout());
    navigate("/");
  };

  return {
    username: {
      get: usernameInput,
      set: setUsernameInput,
      error: usernameError,
      onChange: setUsernameHandler,
      onBlur: checkUsernameHandler,
    },
    password: {
      get: passwordInput,
      set: setPasswordInput,
      error: passwordError,
      onChange: setPasswordHandler,
      onBlur: checkPasswordHandler,
    },
    signin: onSubmitSigninHandler,
    logout: onLogoutHandler,
  };
};

export default useAuth;
