import React from "react";

import classes from "./FilterInput.module.css";

const FilterInput = (props) => {
  const onChangeHandler = (e) => {
    e.preventDefault();
    props.hook.setFiltersHandler({ [props.name]: e.target.value });
  };

  return (
    <div className={classes.container}>
      <input
        type={props.type}
        placeholder={!props.hide ? props.placeholder : undefined}
        disabled={props.hide}
        value={props.hook.filters[props.name]}
        onChange={onChangeHandler}
        style={
          props.hide
            ? {
                cursor: "default",
                borderColor: "var(--white)",
                backgroundColor: "var(--white)",
              }
            : {}
        }
        className={props.type === "number" ? classes["number-input"] : ""}
      />
    </div>
  );
};

export default FilterInput;
