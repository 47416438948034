import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import classes from "./SingleNav.module.css";

const SingleNav = (props) => {
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnterHandler = (e) => {
    e.preventDefault();
    setIsHovered(true);
  };

  const onMouseLeaveHandler = (e) => {
    e.preventDefault();
    setIsHovered(false);
  };

  const isActive = location.pathname === props.path;

  return (
    <div
      className={classes.container}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      {props.path === "/logout" ? (
        <p className={classes["nav"]} onClick={props.onClick}>
          {props.children}
        </p>
      ) : (
        <Link className={classes["nav"]} to={`${props.path}`}>
          {props.children}
        </Link>
      )}
      <div
        className={classes["underline-animation"]}
        style={isActive || isHovered ? { width: "80%" } : { width: "0%" }}
      />
    </div>
  );
};

export default SingleNav;
