import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import handleError from "../../helper/handleError";

let URL;

URL = process.env.REACT_APP_SERVER_URL;

const initialOrderState = {
  errorOrder: false,
  messageOrder: false,
  orders: [],
  order: {},
  newOrders: [],
  totalNumOfOrders: 0,
};

const orderSlice = createSlice({
  name: "order",
  initialState: initialOrderState,
  reducers: {
    setErrorOrder(state, action) {
      state.errorOrder = action.payload.value;
    },
    setMessageOrder(state, action) {
      state.messageOrder = action.payload.value;
    },
    setOrders(state, action) {
      state.orders = action.payload.data.orders;
      state.totalNumOfOrders = action.payload.data.totalNumOfOrders;
    },
    setOrder(state, action) {
      state.order = action.payload.data;
    },
    addOrders(state, action) {
      state.orders = [...state.orders, action.payload.data];
    },
    removeOrderFromOrders(state, action) {
      const _filteredOrders = state.orders.filter(
        (o) => o.id !== action.payload.value
      );
      state.orders = _filteredOrders;
    },
    addModifiedNewOrders(state, action) {
      state.newOrders = action.payload.data;
    },
    addNewOrders(state, action) {
      state.newOrders = [...state.newOrders, action.payload.data];
    },
    removeNewOrder(state, action) {
      const _newOrders = state.newOrders.filter(
        (no) => no.id !== action.payload.data.id
      );
      state.newOrders = _newOrders;
    },
    modifySingleOrder(state, action) {
      const data = action.payload.data;
      const _newOrders = state.newOrders.map((o) => {
        if (o.wineId === data.wineId) {
          return data;
        } else {
          return o;
        }
      });
      state.newOrders = _newOrders;
    },
    clearNewOrders(state) {
      state.newOrders = [];
    },
    setTotalNumOfOrders(state, action) {
      state.totalNumOfOrders = action.payload.value;
    },
    confirmOrders(state, action) {
      const _orders = [];

      state.orders.forEach((o) => {
        if (o.isDone === 0) {
          const _o = { ...o };
          _o.isDone = 1;
          _orders.push(_o);
        } else {
          _orders.push(o);
        }
      });

      state.orders = _orders;
    },
    sortOrders(state, action) {
      const _orders = { ...state.orders };
      const _sorted = _orders.sort();
    },
  },
});

export const getOrders = (token, queryStr = "") => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "get",
        url: `${URL}/orders/get-orders${queryStr}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          orderSlice.actions.setOrders({
            data: {
              orders: response.data.data.orders,
              length: response.data.data.totalNumOfOrders,
            },
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(err, null, dispatch, orderSlice.actions.setErrorOrder);
    }
  };
};

export const getOrder = (orderId, token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "get",
        url: `${URL}/orders/${orderId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          orderSlice.actions.setOrder({
            data: response.data.data,
          })
        );
      }
    } catch (err) {
      handleError(err, null, dispatch, orderSlice.actions.setErrorOrder);
    }
  };
};

export const createOrder = (userInputs, token, wineActions, authActions) => {
  return async (dispatch) => {
    try {
      const _orderData = {
        wineId: userInputs.wineId,
        quantity: userInputs.quantity,
        pickup: userInputs.pickup,
      };

      const axiosOptions = {
        method: "POST",
        url: `${URL}/orders`,
        data: _orderData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(orderSlice.actions.addOrders({ data: response.data.data }));
        dispatch(orderSlice.actions.removeNewOrder({ data: userInputs }));
        dispatch(
          wineActions.updateWinesQuantity({
            data: {
              wineId: userInputs.wineId,
              quantity: -userInputs.quantity,
            },
          })
        );
        dispatch(
          authActions.updateUserShares({
            data: {
              quantity: userInputs.quantity,
              shares: userInputs.wine.shares,
            },
          })
        );
        dispatch(
          orderSlice.actions.setMessageOrder({
            value: `Bestellung erfolgreich kreiert.`,
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(
        err,
        `An error occurred while creating the order.`,
        dispatch,
        orderSlice.actions.setErrorOrder
      );
    }
  };
};

export const createOrderAdmin = (userInputs, token, navigate, FN) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "POST",
        url: `${URL}/orders`,
        data: userInputs,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(orderSlice.actions.addOrders({ data: response.data.data }));
        navigate(`/bestellung/${response.data.data.id}`);
        dispatch(FN(token));
        dispatch(
          orderSlice.actions.setMessageOrder({
            value: "Bestellung erfolgreich kreiert.",
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(
        err,
        `An error occurred while creating the order.`,
        dispatch,
        orderSlice.actions.setErrorOrder
      );
    }
  };
};

export const editOrder = (
  orderId,
  userInputs,
  token,
  navigate,
  FN,
  FNtwo = null
) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "patch",
        url: `${URL}/orders/${orderId}`,
        data: userInputs,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          orderSlice.actions.setOrder({
            data: response.data.data,
          })
        );
        navigate(`/bestellung/${response.data.data.id}`);
        dispatch(FN(token));
        dispatch(
          orderSlice.actions.setMessageOrder({
            value: `Bestellung erfolgreich geändert.`,
          })
        );
        if (FNtwo) {
          FNtwo();
        }
      }
    } catch (err) {
      // console.error(err);
      handleError(
        err,
        `An error occurred while editing the order.`,
        dispatch,
        orderSlice.actions.setErrorOrder
      );
    }
  };
};

export const deleteOrder = (orderId, token, FN) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "delete",
        url: `${URL}/orders/${orderId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(orderSlice.actions.removeOrderFromOrders({ value: orderId }));
        dispatch(
          orderSlice.actions.setMessageOrder({
            value: `Bestellung wurde erfolgreich gelöscht`,
          })
        );
      }
    } catch (err) {
      console.error(err);
      handleError(
        err,
        `An error occurred while deleting order.`,
        dispatch,
        orderSlice.actions.setErrorOrder
      );
    }
  };
};

export const confirmOrders = (orders, token, navigate) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "patch",
        url: `${URL}/orders/confirm-orders`,
        data: { orders },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(orderSlice.actions.confirmOrders());
        navigate("/bestellung");
        dispatch(
          orderSlice.actions.setMessageOrder({
            value: `Bestellung wurde bestätigt.`,
          })
        );
      }
    } catch (err) {
      handleError(
        err,
        `An error occurred while confirming the orders.`,
        dispatch,
        orderSlice.actions.setErrorOrder
      );
    }
  };
};

export default orderSlice;
