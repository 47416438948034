import React from "react";

import classes from "./AddNewBtn.module.css";

const AddNewBtn = (props) => {
  return (
    <div
      className={props.classOverride ? props.classOverride : classes.container}
      onClick={props.onClickHandler}
    >
      <p>{props.children}</p>
    </div>
  );
};

export default AddNewBtn;
