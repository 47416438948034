import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import classes from "./TableRow.module.css";

// STORE
import { deleteOrder } from "../../store/reducers/order-slice";

// COMPONENTS
import TableCell from "../UI/TableCell";

import { TbPencil } from "react-icons/tb";
import { BsTrash } from "react-icons/bs";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const TableRow = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isAuthenticated, token } = useSelector((state) => state.auth);

  const isAdmin = user.isAdmin === 1;
  const isDone = user.isAdmin === 0 && props.data.isDone === 1;

  const redirectToSingleOrderHandler = (e) => {
    e.preventDefault();
    navigate(`${props.data.id}`);
  };

  const redirectToEditOrderHandler = (e) => {
    e.preventDefault();
    navigate(`redigieren/${props.data.id}`);
  };

  const deleteOrderHandler = (e) => {
    e.preventDefault();
    if (!isAuthenticated || !token) return;
    dispatch(deleteOrder(props.data.id, token));
  };

  return (
    <Fragment>
      {props.data.id ? (
        <div
          className={classes.container}
          style={props.style ? props.style : {}}
        >
          <TableCell data={props.data} label={"fullName"} sort={props.sort} />
          <TableCell data={props.data} label={"email"} sort={props.sort} />
          <TableCell data={props.data} label={"street"} sort={props.sort} />
          <TableCell data={props.data} label={"city"} sort={props.sort} />
          <TableCell data={props.data} label={"country"} sort={props.sort} />
          <TableCell data={props.data} label={"wineName"} sort={props.sort} />
          <TableCell data={props.data} label={"quantity"} sort={props.sort} />
          <TableCell
            data={props.data}
            label={"additionalPayment"}
            valute={"€"}
            sort={props.sort}
          />
          <TableCell
            data={props.data}
            label={"totalPrice"}
            valute={"€"}
            sort={props.sort}
          />
          <TableCell
            data={props.data}
            label={"pickup"}
            value={
              (props.data.pickup === 0
                ? "Lieferung an den Wohnort"
                : "Abholung in Bodega") || ""
            }
            sort={props.sort}
          />
          <TableCell
            data={props.data}
            label={"isDone"}
            value={(props.data.isDone === 1 ? "Ja" : "Nein") || ""}
            sort={props.sort}
          />
          <TableCell
            data={props.data}
            label={"createdAt"}
            value={
              new Date(props.data.createdAt).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }) || ""
            }
            sort={props.sort}
          />
          {/* <p>{props.data.fullName}</p>
          <p>{props.data.email}</p>
          <p>{props.data.street}</p>
          <p>{props.data.city}</p>
          <p>{props.data.country}</p>
          <p>{props.data.wineName}</p>
          <p>{props.data.quantity}</p>
          <p>{props.data.additionalPayment} €</p>
          <p>{props.data.totalPrice} €</p>
          <p>
            {(props.data.pickup === 0
              ? "Lieferung an den Wohnort"
              : "Abholung in Bodega") || ""}
          </p>
          <p>{(props.data.isDone === 1 ? "Ja" : "Nein") || ""}</p>
          <p>
            {new Date(props.data.createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }) || ""}
          </p> */}
          {/* <p>{`${props.data.Customer?.firstName} ${
            props.data.Customer?.middleName || ""
          } ${props.data.Customer?.lastName}`}</p>
          <p>{props.data.Customer?.email}</p>
          <p>{props.data.Customer?.street}</p>
          <p>
            {props.data.Customer?.city} {props.data.Customer?.postalCode}
          </p>
          <p>{props.data.Customer?.country}</p>
          <p>{props.data.Wine?.wineName}</p>
          <p>{props.data.quantity}</p>
          <p>{props.data.Wine?.additionalPayment || ""} €</p>
          <p>
            {(props.data.Wine?.additionalPayment * props.data.quantity).toFixed(
              2
            ) || ""}{" "}
            €
          </p>
          <p>
            {(props.data.pickup === 0
              ? "Lieferung an den Wohnort"
              : "Abholung in Bodega") || ""}
          </p>
          <p>{(props.data.isDone === 1 ? "Ja" : "Nein") || ""}</p>
          <p>
            {new Date(props.data.createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }) || ""}
          </p> */}
          <div className={classes.buttons}>
            <div
              className={`${classes.view} ${classes.btn}`}
              onClick={redirectToSingleOrderHandler}
            >
              <MdOutlineRemoveRedEye />
            </div>
            {(isAdmin || !isDone) && (
              <div
                className={`${classes.edit} ${classes.btn}`}
                onClick={redirectToEditOrderHandler}
              >
                <TbPencil />
              </div>
            )}
            {(isAdmin || !isDone) && (
              <div
                className={`${classes.delete} ${classes.btn}`}
                onClick={deleteOrderHandler}
              >
                <BsTrash />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={classes.container} />
      )}
    </Fragment>
  );
};

export default TableRow;
