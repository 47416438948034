import React from "react";

import classes from "./TableCell.module.css";

const TableCell = (props) => {
  return (
    <p
      className={classes.cell}
      style={
        props.sort.field === props.label
          ? { backgroundColor: "var(--cell-sort)" }
          : {}
      }
    >
      {props.value && props.value}
      {!props.value && props.data[props.label]}
      {props.valute && ` ${props.valute}`}
    </p>
  );
};

export default TableCell;
