import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";

import classes from "./SingleCustomerContainer.module.css";

// COMPONENTS
import Title from "../../UI/Title";

// STORE
import { getCustomer } from "../../../store/reducers/customer-slice";
import { customerActions } from "../../../store/redux-store";

const SingleInfo = (props) => {
  return (
    <div className={classes["single-value"]}>
      <p className={classes.label}>{props.label}:</p>
      <p className={classes.value}>{props.value}</p>
    </div>
  );
};

const SingleCustomerContainer = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { user, isAuthenticated, token } = useSelector((state) => state.auth);
  const { customer } = useSelector((state) => state.customer);

  useEffect(() => {
    if (!isAuthenticated || !token || user.isAdmin === 0) return;

    dispatch(getCustomer(params.customerId, token));

    return () => {
      dispatch(customerActions.setCustomer({ data: {} }));
    };
  }, [dispatch, isAuthenticated, params.customerId, token, user.isAdmin]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Title>Kunde</Title>
      </div>
      <div className={classes["inner-container"]}>
        <p
          className={classes.title}
        >{`${customer.firstName} ${customer.lastName}`}</p>
        <div className={classes.separator} />
        <SingleInfo label={"Kunden ID"} value={customer.id} />
        <SingleInfo label={"Email"} value={customer.email} />
        <SingleInfo label={"Land"} value={customer.country} />
        <SingleInfo label={"Stadt"} value={customer.city} />
        <SingleInfo label={"Strasse"} value={customer.street} />
        <SingleInfo label={"PLZ"} value={customer.postalCode} />
      </div>
    </div>
  );
};

export default SingleCustomerContainer;
