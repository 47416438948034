import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import classes from "./Wines.module.css";

// STORE
import { getWines } from "../store/reducers/wine-slice";

const Wines = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWines());
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <Outlet />
    </div>
  );
};

export default Wines;
