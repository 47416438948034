import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import classes from "./OrderCard.module.css";

// COMPONENTS
import CheckBoxInput from "../UI/CheckBoxInput";

// CUSTOM HOOKS
import useOrders from "../../custom-hooks/useOrders";

import {
  MdOutlineKeyboardDoubleArrowUp,
  MdOutlineKeyboardDoubleArrowDown,
  MdClose,
} from "react-icons/md";
import { FiPlus, FiMinus } from "react-icons/fi";
import { orderActions } from "../../store/redux-store";

const SingleOrder = (props) => {
  const dispatch = useDispatch();
  const { newOrders } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.auth);
  const { wines } = useSelector((state) => state.wine);

  const decrementQuantityHandler = (e) => {
    e.preventDefault();

    const _order = newOrders.find((o) => o.id === props.id);
    const _copyOfOrder = { ..._order };

    if (_order.quantity > 1) {
      _copyOfOrder.quantity = _order.quantity - 1;
      dispatch(orderActions.modifySingleOrder({ data: _copyOfOrder }));
    }
  };

  const incrementQuantityHandler = (e) => {
    e.preventDefault();

    const sumAllShares = newOrders.reduce((acc, obj) => {
      return (acc = acc + obj.quantity * obj.wine.shares);
    }, 0);
    const _order = newOrders.find((o) => o.id === props.id);

    const _wineInStorage = wines.find((w) => w.id === _order.wineId);

    if (
      _wineInStorage.stockCount - +_wineInStorage.totalOrders <
      _order.quantity + 1
    ) {
      dispatch(
        orderActions.setErrorOrder({
          value: `Nicht genügend Weine auf Lager`,
        })
      );
      return;
    }

    const _orderAmount = sumAllShares + props.wine.shares;

    if (user.shares.availableShares > _orderAmount) {
      const _copyOfOrder = { ..._order };
      _copyOfOrder.quantity = _order.quantity + 1;
      dispatch(orderActions.modifySingleOrder({ data: _copyOfOrder }));
    } else {
      dispatch(
        orderActions.setErrorOrder({
          value: `Anzahl der Aktien nicht genügend.`,
        })
      );
    }
  };

  const setPickupToZeroHandler = (e) => {
    e.preventDefault();

    const _order = newOrders.find((o) => o.id === props.id);
    const _copyOfOrder = { ..._order };
    _copyOfOrder.pickup = 0;

    if (_order.pickup === 1)
      dispatch(orderActions.modifySingleOrder({ data: _copyOfOrder }));
  };

  const setPickupToOneHandler = (e) => {
    e.preventDefault();

    const _order = newOrders.find((o) => o.id === props.id);
    const _copyOfOrder = { ..._order };
    _copyOfOrder.pickup = 1;

    if (_order.pickup === 0)
      dispatch(orderActions.modifySingleOrder({ data: _copyOfOrder }));
  };

  const removeOrderHandler = (e) => {
    e.preventDefault();

    const _newOrders = newOrders.filter((o) => o.id !== props.id);

    dispatch(orderActions.addModifiedNewOrders({ data: _newOrders }));
  };

  const changeQuantityHandler = (e) => {
    e.preventDefault();
    const value = e.target.value;

    const _order = newOrders.find((o) => o.id === props.id);
    const _copyOfOrder = { ..._order };
    const _wineInStorage = wines.find((w) => w.id === _order.wineId);
    const sumAllShares = newOrders.reduce((acc, obj) => {
      return (acc = acc + obj.quantity * obj.wine.shares);
    }, 0);

    const sumWithoutSelectedOrderShares =
      sumAllShares - _order.quantity * _wineInStorage.shares;

    if (_wineInStorage.stockCount - +_wineInStorage.totalOrders < value) {
      dispatch(
        orderActions.setErrorOrder({
          value: `Nicht genügend Weine auf Lager.`,
        })
      );
      return;
    }

    if (
      sumWithoutSelectedOrderShares + _wineInStorage.shares * value <=
      user.shares.availableShares
    ) {
      _copyOfOrder.quantity = value;
      dispatch(orderActions.modifySingleOrder({ data: _copyOfOrder }));
    }
    // else {
    //   dispatch(
    //     orderActions.setErrorOrder({
    //       value: `Anzahl der Aktien nicht genügend.`,
    //     })
    //   );
    // }
  };

  return (
    <div className={classes["single__order"]}>
      <div className={classes["single__order-title"]}>
        <p>{props.wine.wineName}</p>
        <div
          className={classes["single__order-remove"]}
          onClick={removeOrderHandler}
        >
          <MdClose className={classes["icon-close"]} />
        </div>
      </div>
      <p className={classes["single__order-text"]}>{`Lagerbestand: ${
        props.wine.stockCount - +props.wine.totalOrders
      }`}</p>
      <p
        className={classes["single__order-text"]}
      >{`Anzahl der Aktien: ${props.wine.shares}`}</p>
      <p className={classes["single__order-text"]}>
        {`Zuzahlung: ${props.wine.additionalPayment} €`}
      </p>
      <div className={classes["single__order-inputs"]}>
        <div className={classes["single__order-pickup"]}>
          <CheckBoxInput
            label={"Abholung in Bodega"}
            value={props.order.pickup === 1 ? true : false}
            onClick={setPickupToOneHandler}
          />
          <CheckBoxInput
            label={"Lieferung an den Wohnort"}
            value={props.order.pickup === 0 ? true : false}
            onClick={setPickupToZeroHandler}
          />
        </div>
        <div className={classes["single__order-quantity"]}>
          <div
            className={classes["single__order-quantity-btn"]}
            onClick={decrementQuantityHandler}
          >
            <FiMinus />
          </div>
          <input
            type="number"
            value={props.order.quantity}
            onChange={changeQuantityHandler}
          />
          <div
            className={classes["single__order-quantity-btn"]}
            onClick={incrementQuantityHandler}
          >
            <FiPlus />
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderCard = (props) => {
  const orderHook = useOrders();
  const dispatch = useDispatch();
  const { newOrders } = useSelector((state) => state.order);
  const { wines } = useSelector((state) => state.wine);

  const [totalShares, setTotalShares] = useState(0);
  const [totalAdditionalPayment, setTotalAdditionalPayment] = useState(0);
  const [show, setShow] = useState(true);
  const [_newOrdersRenderer, _setNewOrdersRenderer] = useState([]);

  useEffect(() => {
    const _newOrders = newOrders.map((no) => {
      const _wine = wines.find((w) => w.id === no.wineId);

      return (
        <SingleOrder
          key={no.id}
          id={no.id}
          wine={_wine}
          order={{
            quantity: no.quantity,
            pickup: no.pickup,
          }}
        />
      );
    });

    const _totalAdPayment = newOrders.reduce((add, o) => {
      return add + o.wine.additionalPayment * o.quantity;
    }, 0);
    const _totalShares = newOrders.reduce((add, o) => {
      return add + o.wine.shares * o.quantity;
    }, 0);

    setTotalAdditionalPayment(_totalAdPayment.toFixed(2));
    setTotalShares(_totalShares);
    _setNewOrdersRenderer(_newOrders);
  }, [newOrders, wines]);

  const showOrderCartHandler = (e) => {
    e.preventDefault();

    setShow(!show);
  };

  return (
    <div className={classes.container} style={{ pointerEvents: "none" }}>
      <div
        className={classes["orders-container"]}
        style={
          show
            ? { transform: `translateY(0)`, pointerEvents: "auto" }
            : { transform: `translateY(110%)`, pointerEvents: "auto" }
        }
      >
        <div className={classes.orders}>{_newOrdersRenderer}</div>
        <div className={classes["orders-total"]}>
          <p className={classes["label"]}>Gesamt:</p>
          <div className={classes["values"]}>
            <p
              className={classes["value"]}
            >{`Aktien gesamt: ${totalShares}`}</p>
            <p
              className={classes["value"]}
            >{`Zuzahlung gesamt: ${totalAdditionalPayment} €`}</p>
          </div>
        </div>
      </div>
      <div className={classes.buttons} style={{ pointerEvents: "auto" }}>
        <div
          className={classes["show-orders-btn"]}
          onClick={showOrderCartHandler}
        >
          <MdOutlineKeyboardDoubleArrowDown
            className={classes.icon}
            style={
              show
                ? { transform: `rotate(0deg)` }
                : { transform: `rotate(180deg)` }
            }
          />
          {/* {show ? (
            <MdOutlineKeyboardDoubleArrowDown className={classes.icon} />
          ) : (
            <MdOutlineKeyboardDoubleArrowUp className={classes.icon} />
          )} */}
        </div>
        <div
          className={classes["confirm-order-btn"]}
          onClick={orderHook.createOrderHandler}
        >
          {`Bestellung bestätigen (${newOrders.length})`}
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
