import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

import classes from "./ShareFormContainer.module.css";

// COMPONENTS
import Title from "../../UI/Title";
import Input from "../../UI/Input";

// STORE
import {
  getShares,
  getShare,
  createShare,
  editShare,
} from "../../../store/reducers/share-slice";
import { getCustomers } from "../../../store/reducers/customer-slice";

const ShareFormContainer = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customers } = useSelector((state) => state.customer);
  const { isAuthenticated, token, user } = useSelector((state) => state.auth);
  const { share } = useSelector((state) => state.share);

  // INPUTS
  const [_customersList, _setCustomersList] = useState([]);
  const [customerId, setCustomerId] = useState(0);
  const [shares, setShares] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [identityCardNumber, setIdentityCardNumber] = useState("");
  const [shareIdentificationNumber, setShareIdentificationNumber] =
    useState("");
  const [identificationString, setIdentificationString] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    dispatch(getCustomers(token));
  }, [dispatch, token]);

  useEffect(() => {
    const _customers = customers.map((c) => {
      return (
        <option
          key={c.id}
          className={classes.option}
          value={c.id}
          onClick={setCustomerIdHandler}
        >{`${c.email} - ${c.firstName} ${c.lastName}`}</option>
      );
    });

    _setCustomersList(_customers);

    if (props.type === "create" && customers.length > 0)
      setCustomerId(customers[0]?.id);
  }, [customers, props.type]);

  useEffect(() => {
    if (props.type !== "edit") return;
    if (!isAuthenticated || !token || user.isAdmin === 0) return;

    if (share?.shareId && share?.shareId === +params.shareId) return;

    dispatch(getShare(params.shareId, token));
  }, [
    dispatch,
    isAuthenticated,
    token,
    user.isAdmin,
    props.type,
    share,
    params.shareId,
  ]);

  useEffect(() => {
    if (props.type !== "edit") return;
    if (!share?.shareId) return;

    setCustomerId(share.customerId || 1);
    setShares(share.shares || "");
    setTotalPrice(share.totalPrice || "");
    setIdentityCardNumber(share.identityCardNumber || "");
    setShareIdentificationNumber(share.shareIdentificationNumber || "");
    setIdentificationString(share.identificationString || "");
    setComment(share.comment || "");
  }, [props.type, share]);

  const setCustomerIdHandler = (e) => {
    e.preventDefault();
    setCustomerId(e.target.value);
  };

  const setSharesHandler = (e) => {
    e.preventDefault();
    setShares(e.target.value);
  };

  const setTotalPriceHandler = (e) => {
    e.preventDefault();
    setTotalPrice(e.target.value);
  };

  const setIdentityCardNumberHandler = (e) => {
    e.preventDefault();
    setIdentityCardNumber(e.target.value);
  };

  const setShareIdentificationNumberHandler = (e) => {
    e.preventDefault();
    setShareIdentificationNumber(e.target.value);
  };

  const setIdentificationStringHandler = (e) => {
    e.preventDefault();
    setIdentificationString(e.target.value);
  };

  const setCommentHandler = (e) => {
    e.preventDefault();
    setComment(e.target.value);
  };

  const goBackToSharesHandler = (e) => {
    e.preventDefault();
    navigate("/aktien");
  };

  const createShareHandler = (e) => {
    e.preventDefault();

    if (!isAuthenticated || !token || user.isAdmin === 0) return;

    const data = {};

    if (customerId) data.customerId = +customerId;
    if (shares) data.shares = shares;
    if (totalPrice) data.totalPrice = totalPrice;
    if (identityCardNumber) data.identityCardNumber = identityCardNumber;
    if (shareIdentificationNumber)
      data.shareIdentificationNumber = shareIdentificationNumber;
    if (identificationString) data.identificationString = identificationString;
    if (comment) data.comment = comment;

    if (props.type === "create") {
      dispatch(createShare(data, token, navigate, getShares));
    }

    if (props.type === "edit") {
      dispatch(editShare(params.shareId, data, token, navigate, getShares));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {props.type === "create" && <Title>Neue Aktien</Title>}
        {props.type === "edit" && <Title>Bearbeitung der Aktien</Title>}
      </div>
      <div className={classes["inner-container"]}>
        <select
          value={customerId}
          className={classes.customer}
          onChange={setCustomerIdHandler}
        >
          {_customersList}
        </select>
        <Input
          type={"text"}
          name={"shares"}
          label={"Aktien Anzahl"}
          value={shares}
          onChange={setSharesHandler}
        />
        <Input
          type={"text"}
          name={"totalPrice"}
          label={"Gesamt Preis"}
          value={totalPrice}
          onChange={setTotalPriceHandler}
        />
        <Input
          type={"text"}
          name={"identityCardNumber"}
          label={"Personalausweis Nummer"}
          value={identityCardNumber}
          onChange={setIdentityCardNumberHandler}
        />
        <Input
          type={"text"}
          name={"shareIdentificationNumber"}
          label={"Aktien Identifikations Nummer"}
          value={shareIdentificationNumber}
          onChange={setShareIdentificationNumberHandler}
        />
        <Input
          type={"text"}
          name={"identificationString"}
          label={"Identifikationzeichenkette"}
          value={identificationString}
          onChange={setIdentificationStringHandler}
        />
        <Input
          type={"text"}
          name={"comment"}
          label={"Komment"}
          value={comment}
          onChange={setCommentHandler}
        />
        <div className={classes.buttons}>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--white)`,
              color: `var(--c-accent-gray)`,
            }}
            onClick={goBackToSharesHandler}
          >
            Abbrechen
          </div>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--c-primary-red)`,
              color: `var(--white)`,
            }}
            onClick={createShareHandler}
          >
            Speichern
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareFormContainer;
