import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";

import classes from "./SingleOrderContainer.module.css";

// COMPONENTS
import Title from "../../UI/Title";

// STORE
import { orderActions } from "../../../store/redux-store";
import { getOrder } from "../../../store/reducers/order-slice";

const SingleInfo = (props) => {
  return (
    <div className={classes["single-value"]}>
      <p className={classes.label}>{props.label}:</p>
      <p className={classes.value}>{props.value}</p>
    </div>
  );
};

const SingleOrderContainer = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { user, isAuthenticated, token } = useSelector((state) => state.auth);
  const { order } = useSelector((state) => state.order);

  useEffect(() => {
    if (!isAuthenticated || !token) return;

    dispatch(getOrder(params.orderId, token));

    return () => {
      dispatch(orderActions.setOrder({ data: {} }));
    };
  }, [dispatch, isAuthenticated, params.orderId, token, user.isAdmin]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Title>Bestellung</Title>
      </div>
      {order.id && (
        <div className={classes["inner-container"]}>
          <p className={classes.title}>{`${order.Customer?.firstName || ""} ${
            order.Customer?.lastName || ""
          }`}</p>
          <div className={classes.separator} />
          <SingleInfo label={"Bestellung ID"} value={order.id} />
          <SingleInfo label={"Email"} value={order.Customer?.email || ""} />
          <SingleInfo label={"Weinname"} value={order.Wine?.wineName || ""} />
          <SingleInfo label={"Menge"} value={order.quantity} />
          <SingleInfo
            label={"Zuzahlung pro Flasche"}
            value={`${order.Wine?.additionalPayment || "0"} €`}
          />
          <SingleInfo
            label={"Zuzahlung insgesamt"}
            value={`${
              (order.quantity * order.Wine?.additionalPayment).toFixed(2) || "0"
            } €`}
          />
          <SingleInfo
            label={"Versand"}
            value={
              order.pickup === 0
                ? "Lieferung an den Wohnort"
                : "Abholung in Bodega"
            }
          />
          <SingleInfo
            label={"Datum"}
            value={new Date(order.createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          />
        </div>
      )}
    </div>
  );
};

export default SingleOrderContainer;
