import React, { useState } from "react";

import classes from "./Input.module.css";

const Input = (props) => {
  const [focused, setFocused] = useState(false);

  const onFocusHandler = (e) => {
    e.preventDefault();
    setFocused(true);
  };

  const onBlurHandler = (e) => {
    e.preventDefault();

    setFocused(false);
    if (props.onBlur) {
      props.onBlur(e.target.value);
    }
  };

  return (
    <div
      className={classes.container}
      style={
        focused
          ? { borderColor: `var(--c-primary-red)` }
          : { borderColor: `var(--c-border-image)` }
      }
    >
      <label htmlFor={props.name}>{props.label || ""}:</label>
      <input
        id={props.name}
        name={props.name}
        type={props.type}
        onChange={props.onChange}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        value={props.value}
        className={props.type === "number" ? `${classes["number-input"]}` : ""}
      />
    </div>
  );
};

export default Input;
