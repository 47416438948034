import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classes from "./OrderTable.module.css";

// COMPONENTS
import TableRow from "./TableRow";
import SingleTableHeader from "../UI/SingleTableHeader";

import sortDynamic from "../../helper/sort-array";

const OrderTable = (props) => {
  const { orders } = useSelector((state) => state.order);
  const [_modifiedOrders, _setModifiedOrders] = useState([]);
  const [_ordersRenderer, _setOrdersRenderer] = useState([]);

  const hook = props.sortHook;

  useEffect(() => {
    const _ords = orders.map((o) => {
      return {
        id: o.id,
        fullName: `${o.Customer?.firstName} ${o.Customer?.lastName}`,
        email: o.Customer?.email,
        street: o.Customer?.street,
        city: `${o.Customer?.city} ${o.Customer?.postalCode}`,
        country: o.Customer?.country,
        wineName: o.Wine?.wineName,
        quantity: +o.quantity,
        additionalPayment: +o.Wine?.additionalPayment,
        totalPrice: +(o.Wine?.additionalPayment * o.quantity).toFixed(2),
        pickup: +o.pickup,
        isDone: +o.isDone,
        createdAt: o.createdAt,
      };
    });

    if (hook.sort.field && hook.sort.by) {
      const _sorted = _ords.sort(sortDynamic(hook.sort.field, hook.sort.by));
      _setModifiedOrders(_sorted);
    } else {
      _setModifiedOrders(_ords);
    }
  }, [orders, hook.sort]);

  useEffect(() => {
    const _oRenderer = _modifiedOrders.map((o, i) => (
      <TableRow
        data={o}
        sort={hook.sort}
        key={o.id}
        style={
          i % 2 !== 0 ? { backgroundColor: "var(--c-background-table)" } : {}
        }
      />
    ));

    _setOrdersRenderer(_oRenderer);
  }, [_modifiedOrders, hook.sort]);
  // }, [orders]);

  return (
    <div className={classes.container}>
      <ul>
        <div className={classes.header}>
          <SingleTableHeader
            label="Kunde"
            name="fullName"
            hook={props.sortHook}
          />
          <SingleTableHeader label="Email" name="email" hook={props.sortHook} />
          <SingleTableHeader
            label="Adresse"
            name="street"
            hook={props.sortHook}
          />
          <SingleTableHeader label="Stadt" name="city" hook={props.sortHook} />
          <SingleTableHeader
            label="Land"
            name="country"
            hook={props.sortHook}
          />
          <SingleTableHeader
            label="Wein"
            name="wineName"
            hook={props.sortHook}
          />
          <SingleTableHeader
            label="Menge"
            name="quantity"
            hook={props.sortHook}
          />
          <SingleTableHeader
            label="Zuzahlung pro Flasche"
            name="additionalPayment"
            hook={props.sortHook}
          />
          <SingleTableHeader
            label="Zuzahlung Insgesamt"
            name="totalPrice"
            hook={props.sortHook}
          />
          <SingleTableHeader
            label="Versand"
            name="pickup"
            hook={props.sortHook}
          />
          <SingleTableHeader
            label="Abgeschlossen"
            name="isDone"
            hook={props.sortHook}
          />
          <SingleTableHeader
            label="Datum"
            name="createdAt"
            hook={props.sortHook}
          />
          {/* <div className={classes["table-header"]}>Kunde</div>
          <div className={classes["table-header"]}>Email</div>
          <div className={classes["table-header"]}>Adresse</div>
          <div className={classes["table-header"]}>Stadt</div>
          <div className={classes["table-header"]}>Land</div>
          <div className={classes["table-header"]}>Wein</div>
          <div className={classes["table-header"]}>Menge</div>
          <div className={classes["table-header"]}>ZpF</div>
          <div className={classes["table-header"]}>Zuzahlung I.</div>
          <div className={classes["table-header"]}>Versand</div>
          <div className={classes["table-header"]}>Abg</div>
          <div className={classes["table-header"]}>Datum</div> */}
          <div
            style={{ border: "none" }}
            className={classes["table-header"]}
          ></div>
          {/* <div className={classes["table-header"]}>Zuzahlung pro Flasche</div> */}
          {/* <div className={classes["table-header"]}>Zuzahlung Insgesamt</div> */}
          {/* <div className={classes["table-header"]}>Abgeschlossen</div> */}
        </div>
        {_ordersRenderer}
      </ul>
    </div>
  );
};

export default OrderTable;
