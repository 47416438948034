import React from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import classes from "./CustomersTableRow.module.css";

// STORE
import {
  deleteCustomer,
  getCustomers,
} from "../../store/reducers/customer-slice";

// COMPONENTS
import TableCell from "../UI/TableCell";

import { TbPencil } from "react-icons/tb";
import { BsTrash } from "react-icons/bs";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const CustomersTableRow = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isAuthenticated, token } = useSelector((state) => state.auth);

  const redirectToUserHandler = (e) => {
    e.preventDefault();
    navigate(`${props.data.id}`);
  };

  const redirectToEditUserHandler = (e) => {
    e.preventDefault();
    navigate(`redigieren/${props.data.id}`);
  };

  const deleteCustomerHandler = (e) => {
    e.preventDefault();
    if (!isAuthenticated || !token || user.isAdmin === 0) return;

    dispatch(deleteCustomer(props.data.id, token, getCustomers));
  };

  return (
    <div className={classes.container} style={props.style}>
      <TableCell data={props.data} label={"firstName"} sort={props.sort} />
      <TableCell data={props.data} label={"lastName"} sort={props.sort} />
      <TableCell data={props.data} label={"email"} sort={props.sort} />
      <TableCell data={props.data} label={"birthday"} sort={props.sort} />
      <TableCell data={props.data} label={"phoneNumber"} sort={props.sort} />
      <TableCell data={props.data} label={"privateNumber"} sort={props.sort} />
      <TableCell data={props.data} label={"street"} sort={props.sort} />
      <TableCell data={props.data} label={"city"} sort={props.sort} />
      <TableCell data={props.data} label={"country"} sort={props.sort} />
      <TableCell
        data={props.data}
        label={"identificationString"}
        sort={props.sort}
      />
      <TableCell data={props.data} label={"totalOrders"} sort={props.sort} />
      <div className={classes.buttons}>
        <div
          className={`${classes.view} ${classes.btn}`}
          onClick={redirectToUserHandler}
        >
          <MdOutlineRemoveRedEye />
        </div>
        <div
          className={`${classes.edit} ${classes.btn}`}
          onClick={redirectToEditUserHandler}
        >
          <TbPencil />
        </div>
        <div
          className={`${classes.delete} ${classes.btn}`}
          onClick={deleteCustomerHandler}
        >
          <BsTrash />
        </div>
      </div>
    </div>
  );
};

export default CustomersTableRow;
