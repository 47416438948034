import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// STORE
import { getCustomers } from "../store/reducers/customer-slice";

let init = false;

const useCustomersFilter = (props) => {
  const dispatch = useDispatch();
  const { token, isAuthenticated, user } = useSelector((state) => state.auth);

  const [fetchCustomers, setFetchCustomers] = useState(false);
  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthday: "",
    phoneNumber: "",
    privateNumber: "",
    street: "",
    city: "",
    country: "",
    identificationString: "",
    numberOfOrders: "",
  });

  const [sort, setSort] = useState({
    field: "",
    by: "",
  });

  const setSortHandler = (field) => {
    if (sort.field === field) {
      if (!sort.by) {
        setSort({
          field: sort.field,
          by: "asc",
        });
        return;
      }
      const _by = sort.by === "asc" ? "desc" : "asc";
      setSort({
        field: sort.field,
        by: _by,
      });
    } else {
      setSort({
        field: field,
        by: "asc",
      });
    }
  };

  useEffect(() => {
    if (user.isAdmin === 0 || !isAuthenticated || !token) return;
    dispatch(getCustomers(token));
    init = true;
  }, [dispatch, token, isAuthenticated, user]);

  const setFiltersHandler = (data) => {
    setFilters((oldValue) => {
      return { ...oldValue, ...data };
    });
  };

  const createQueryString = (data) => {
    let queryString = `?`;

    const filtersKeys = Object.keys(data);

    filtersKeys.forEach((filter, i) => {
      if (!data[filter]) return;
      let value = ``;

      if (i === 0 || queryString.length === 1) {
        value = `${filter}=${data[filter]}`;
      } else {
        value = `&${filter}=${data[filter]}`;
      }

      queryString = queryString + value;
    });

    return queryString;
  };

  useEffect(() => {
    if (!init) return;

    if (!isAuthenticated || !token) return;
    const queryStr = createQueryString(filters);
    dispatch(getCustomers(token, queryStr));
  }, [dispatch, token, filters, isAuthenticated]);

  return {
    setFiltersHandler,
    setSortHandler,
    filters,
    sort,
  };
};

export default useCustomersFilter;
