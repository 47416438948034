import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

// STORE
import { authActions } from "../../store/redux-store";
import { setPickedYear } from "../../store/reducers/auth-slice";

import classes from "./SettingsContainer.module.css";

// COMPONENTS
import Title from "../UI/Title";

const generateYears = () => {
  const currentYear = new Date().getFullYear() + 10;
  const startYear = 2010;
  const array = [];

  for (let i = startYear; i <= currentYear; i++) {
    array.push(i);
  }

  return array;
};

const SettingsContainer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [yearInput, setYearInput] = useState();
  const { year, isAuthenticated, token, user } = useSelector(
    (state) => state.auth
  );

  const changeYearHandler = (e) => {
    e.preventDefault();
    setYearInput(e.target.value);
  };

  useEffect(() => {
    setYearInput(year);
  }, [year]);

  const years = generateYears();

  // YEARS
  const _yearsList = years.map((y) => {
    return (
      <option
        key={y}
        className={classes.option}
        value={y}
        // onClick={setWineIdHandler}
      >{`${y}`}</option>
    );
  });

  const goBackHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const saveSettingsHandler = (e) => {
    e.preventDefault();
    if (!isAuthenticated || !token || user.isAdmin !== 1) return;
    dispatch(setPickedYear({ year: yearInput }, token));
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Title>Einstellungen</Title>
      </div>
      <div className={classes["inner-container"]}>
        <h5>Das Jahr:</h5>
        <select
          value={yearInput}
          className={classes.select}
          onChange={changeYearHandler}
        >
          {_yearsList}
        </select>
        <div className={classes.buttons}>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--white)`,
              color: `var(--c-accent-gray)`,
            }}
            onClick={goBackHandler}
          >
            Abbrechen
          </div>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--c-primary-red)`,
              color: `var(--white)`,
            }}
            onClick={saveSettingsHandler}
          >
            Das Jahr Einstellen
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsContainer;
