import React from "react";

import classes from "./SharesFilters.module.css";

// COMPONENTS
import FilterInput from "../orders/FilterInput";

import { BsFilterRight } from "react-icons/bs";

const filterSharesHandler = (e) => {
  e.preventDefault();
};

const SharesFilters = (props) => {
  return (
    <form className={classes.container} onSubmit={filterSharesHandler}>
      <FilterInput
        placeholder={"Kunde"}
        type="text"
        name="fullName"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Email"}
        type="text"
        name="email"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Von"}
        type="number"
        name="minShares"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Bis"}
        type="number"
        name="maxShares"
        hook={props.hook}
      />
      {/* <FilterInput
        placeholder={"Aktien Anzahl"}
        type="number"
        name="shares"
        hook={props.hook}
      /> */}
      <FilterInput
        placeholder={"Von"}
        type="number"
        name="minTotalPrice"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Bis"}
        type="number"
        name="maxTotalPrice"
        hook={props.hook}
      />
      {/* <FilterInput
        placeholder={"Gesamt Preis"}
        type="number"
        name="totalPrice"
        hook={props.hook}
      /> */}
      <FilterInput
        placeholder={"Aktiennummer"}
        type="number"
        name="shareIdentificationNumber"
        hook={props.hook}
      />
      <FilterInput
        placeholder={"Kommentar"}
        type="text"
        name="comment"
        hook={props.hook}
      />
      <div className={classes.button}>
        <p>Filters</p>
        <BsFilterRight />
      </div>
    </form>
  );
};

export default SharesFilters;
