import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";

import classes from "./SingleShareContainer.module.css";

// COMPONENTS
import Title from "../../UI/Title";

// STORE
import { shareActions } from "../../../store/redux-store";
import { getShare } from "../../../store/reducers/share-slice";

const SingleInfo = (props) => {
  return (
    <div className={classes["single-value"]}>
      <p className={classes.label}>{props.label}:</p>
      <p className={classes.value}>{props.value}</p>
    </div>
  );
};

const SingleShareContainer = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { user, isAuthenticated, token } = useSelector((state) => state.auth);
  const { share } = useSelector((state) => state.share);

  useEffect(() => {
    if (!isAuthenticated || !token || user.isAdmin === 0) return;

    dispatch(getShare(params.shareId, token));

    return () => {
      dispatch(shareActions.setShare({ data: {} }));
    };
  }, [dispatch, isAuthenticated, token, user.isAdmin, params.shareId]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Title>Aktien</Title>
      </div>
      {share.shareId && (
        <div className={classes["inner-container"]}>
          <p
            className={classes.title}
          >{`${share.Customer?.firstName} ${share.Customer?.lastName}`}</p>
          <div className={classes.separator} />
          <SingleInfo label={"Aktien ID"} value={share.shareId || ""} />
          <SingleInfo label={"Aktien Anzahl"} value={share.shares || ""} />
          <SingleInfo label={"Gesamt Preis"} value={share.totalPrice || ""} />
          <SingleInfo
            label={"Personalausweis Nummer"}
            value={share.identityCardNumber || ""}
          />
          <SingleInfo
            label={"AktienIdentifikations Nummer"}
            value={share.shareIdentificationNumber || ""}
          />
          <SingleInfo
            label={"Identifikationzeichenkette"}
            value={share.identificationString || ""}
          />
          <SingleInfo label={"Kommentar"} value={share.comment || ""} />
        </div>
      )}
    </div>
  );
};

export default SingleShareContainer;
