import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import classes from "./CustomerSettingsContainer.module.css";

// COMPONENTS
import Title from "../../UI/Title";
import Input from "../../UI/Input";

// STORE
import {
  getCustomer,
  editCustomer,
} from "../../../store/reducers/customer-slice";

const CustomerSettingsContainer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated, token, user } = useSelector((state) => state.auth);
  const { customer } = useSelector((state) => state.customer);

  const [streetInput, setStreetInput] = useState("");
  const [postalCodeInput, setPostalCodeInput] = useState("");
  const [cityInput, setCityInput] = useState("");
  const [countryInput, setCountryInput] = useState("");

  useEffect(() => {
    if (!isAuthenticated || !token || !user.customerId) return;
    dispatch(getCustomer(user.customerId, token));
  }, [dispatch, isAuthenticated, token, user.customerId]);

  useEffect(() => {
    if (!customer.id) return;

    setStreetInput(customer.street);
    setPostalCodeInput(customer.postalCode);
    setCityInput(customer.city);
    setCountryInput(customer.country);
  }, [customer]);

  const setStreetHandler = (e) => {
    e.preventDefault();
    setStreetInput(e.target.value);
  };

  const setPostalCodeHandler = (e) => {
    e.preventDefault();
    setPostalCodeInput(e.target.value);
  };

  const setCityHandler = (e) => {
    e.preventDefault();
    setCityInput(e.target.value);
  };

  const setCountryHandler = (e) => {
    e.preventDefault();
    setCountryInput(e.target.value);
  };

  const goBackHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const editCustomerHandler = (e) => {
    e.preventDefault();

    if (!isAuthenticated || !token || !user.customerId) return;

    const data = {
      street: streetInput,
      postalCode: postalCodeInput,
      city: cityInput,
      country: countryInput,
    };

    dispatch(editCustomer(user.customerId, data, token));
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Title>Einstellungen</Title>
      </div>
      <div className={classes["inner-container"]}>
        <Input
          type={"text"}
          name={"street"}
          label={"Strasse"}
          value={streetInput}
          onChange={setStreetHandler}
        />
        <Input
          type={"text"}
          name={"postalCode"}
          label={"PLZ"}
          value={postalCodeInput}
          onChange={setPostalCodeHandler}
        />
        <Input
          type={"text"}
          name={"city"}
          label={"Stadt"}
          value={cityInput}
          onChange={setCityHandler}
        />
        <Input
          type={"text"}
          name={"country"}
          label={"Land"}
          value={countryInput}
          onChange={setCountryHandler}
        />
        <div className={classes.buttons}>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--white)`,
              color: `var(--c-accent-gray)`,
            }}
            onClick={goBackHandler}
          >
            Abbrechen
          </div>
          <div
            className={classes.btn}
            style={{
              backgroundColor: `var(--c-primary-red)`,
              color: `var(--white)`,
            }}
            onClick={editCustomerHandler}
          >
            Speichern
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSettingsContainer;
