import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import "./App.css";

// COMPONENTS
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Error from "./components/UI/Error";
import Message from "./components/UI/Message";
import RedirectToLogin from "./components/REDIRECT/RedirectToLogin";

// PAGES
import Home from "./pages/Home";
import Orders from "./pages/Orders";
import Wines from "./pages/Wines";
import Shares from "./pages/Shares";
import Customers from "./pages/Customers";
import Settings from "./pages/Settings";
import CustomerSettings from "./pages/CustomerSettings";

// NESTED ROUTES
import WinesRoutes from "./nested-routes/WinesRoutes";
import CustomersRoutes from "./nested-routes/CustomersRoutes";
import SharesRoutes from "./nested-routes/SharesRoutes";
import OrdersRoutes from "./nested-routes/OrdersRoutes";

// CUSTOM HOOKS
import useAuth from "./custom-hooks/useAuth";
import { useDispatch } from "react-redux";
import useAlert from "./custom-hooks/useAlert";

// STORE
import { getWines } from "./store/reducers/wine-slice";
import { getPickedYear } from "./store/reducers/auth-slice";

function App() {
  const dispatch = useDispatch();
  const authHook = useAuth();
  const { error, message } = useAlert();
  const { year, isAuthenticated, token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getWines());
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated || !token) return;

    if (!year) {
      dispatch(getPickedYear(token));
    }
  }, [isAuthenticated, dispatch, token, year]);

  return (
    <div className="App">
      <Error data={{ message, error }} />
      <Message data={{ message, error }} />
      <Header hook={authHook} />
      <Routes>
        <Route path="/" element={<Home hook={authHook} />} />
        <Route path="/einstellungen" element={<Settings />} />
        <Route path="/kundendata" element={<CustomerSettings />} />
        <Route path="/bestellung/*" element={<Orders />}>
          <Route path="*" element={<OrdersRoutes />} />
        </Route>
        <Route path="/weine/*" element={<Wines />}>
          <Route path="*" element={<WinesRoutes />} />
        </Route>
        <Route path="/aktien/*" element={<Shares />}>
          <Route path="*" element={<SharesRoutes />} />
        </Route>
        <Route path="/kunden/*" element={<Customers />}>
          <Route path="*" element={<CustomersRoutes />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
