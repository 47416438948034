import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// STORE
import { getOrders } from "../store/reducers/order-slice";

let init = false;

const useOrdersFilter = (props) => {
  const dispatch = useDispatch();
  const { token, isAuthenticated, user, year } = useSelector(
    (state) => state.auth
  );

  const [fetchOrders, setFetchOrders] = useState(false);
  const [filters, setFilters] = useState({
    fullName: "",
    email: "",
    street: "",
    city: "",
    country: "",
    wineName: "",
    quantity: "",
    additionalPayment: "",
    totalPayment: "",
    pickup: "",
    isDone: "",
  });

  const [yearInput, setYearInput] = useState();

  const setYearInputHandler = (value) => {
    if (user.isAdmin !== 1) return;
    if (!value) return;

    setYearInput(value);
  };

  useEffect(() => {
    if (init) return;
    setYearInput(year);
  }, [year]);

  useEffect(() => {
    if (!isAuthenticated || !token) return;
    dispatch(getOrders(token));
    init = true;
  }, [dispatch, token, isAuthenticated]);

  const setFiltersHandler = (data) => {
    setFilters((oldValue) => {
      return { ...oldValue, ...data };
    });
  };

  const createQueryString = (data) => {
    let queryString = `?`;

    const filtersKeys = Object.keys(data);

    filtersKeys.forEach((filter, i) => {
      if (!data[filter] && data[filter] !== 0) return;
      let value = ``;

      if (i === 0) {
        value = `${filter}=${data[filter]}`;
      } else {
        value = `&${filter}=${data[filter]}`;
      }

      queryString = queryString + value;
    });

    return queryString;
  };

  useEffect(() => {
    if (!init) return;

    if (!isAuthenticated || !token) return;
    let queryStr = createQueryString(filters);

    if (user.isAdmin === 1) {
      queryStr = queryStr + `&year=${yearInput}`;
    }

    dispatch(getOrders(token, queryStr));
  }, [
    dispatch,
    token,
    filters,
    fetchOrders,
    isAuthenticated,
    user.isAdmin,
    yearInput,
    year,
  ]);

  return {
    setFiltersHandler,
    filters,
    setYearInputHandler,
    yearInput,
  };
};

export default useOrdersFilter;
