import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

//STORE
import {
  alertActions,
  customerActions,
  orderActions,
  wineActions,
  shareActions,
  authActions,
} from "../store/redux-store";
// import { authActions } from "../store/redux-store";

const SHOW_ALERT_MESSAGE = 3;

const useAlert = () => {
  const dispatch = useDispatch();

  const { error, message } = useSelector((state) => state.alert);
  const { errorAuth, messageAuth } = useSelector((state) => state.auth);
  const { errorCustomer, messageCustomer } = useSelector(
    (state) => state.customer
  );
  const { errorOrder, messageOrder } = useSelector((state) => state.order);
  const { errorWine, messageWine } = useSelector((state) => state.wine);
  const { errorShare, messageShare } = useSelector((state) => state.share);

  const removeAllErrorAlerts = useCallback(() => {
    dispatch(alertActions.setError({ error: null }));
    dispatch(authActions.setErrorAuth({ value: null }));
    dispatch(customerActions.setErrorCustomer({ value: null }));
    dispatch(orderActions.setErrorOrder({ value: null }));
    dispatch(wineActions.setErrorWine({ value: null }));
    dispatch(shareActions.setErrorShare({ value: null }));
  }, [dispatch]);
  const removeAllMessageAlerts = useCallback(() => {
    dispatch(alertActions.setMessage({ message: null }));
    dispatch(authActions.setMessageAuth({ value: null }));
    dispatch(customerActions.setMessageCustomer({ value: null }));
    dispatch(orderActions.setMessageOrder({ value: null }));
    dispatch(wineActions.setMessageWine({ value: null }));
    dispatch(shareActions.setMessageShare({ value: null }));
  }, [dispatch]);

  const handleError = useCallback(
    (errAlert) => {
      if (errAlert === null) return;
      dispatch(alertActions.setError({ error: errAlert }));
      let timeout = setTimeout(() => {
        removeAllErrorAlerts();
      }, 1000 * SHOW_ALERT_MESSAGE);

      return () => {
        clearTimeout(timeout);
      };
    },
    [dispatch, removeAllErrorAlerts]
  );
  const handleMessage = useCallback(
    (msgAlert) => {
      if (msgAlert === null) return;
      dispatch(alertActions.setMessage({ message: msgAlert }));
      let timeout = setTimeout(() => {
        removeAllMessageAlerts();
      }, 1000 * SHOW_ALERT_MESSAGE);

      return () => {
        clearTimeout(timeout);
      };
    },
    [dispatch, removeAllMessageAlerts]
  );

  ////////////////////////////////////
  // CHECK CUSTOMER ALERTS AND SET THEM //
  useEffect(() => {
    handleError(errorCustomer);
  }, [errorCustomer, handleError]);
  useEffect(() => {
    handleMessage(messageCustomer);
  }, [messageCustomer, handleMessage]);

  ////////////////////////////////////
  // CHECK AUTH ALERTS AND SET THEM //
  useEffect(() => {
    handleError(errorAuth);
  }, [errorAuth, handleError]);
  useEffect(() => {
    handleMessage(messageAuth);
  }, [messageAuth, handleMessage]);

  ////////////////////////////////////
  // CHECK SHARE ALERTS AND SET THEM //
  useEffect(() => {
    handleError(errorShare);
  }, [errorShare, handleError]);
  useEffect(() => {
    handleMessage(messageShare);
  }, [messageShare, handleMessage]);

  ////////////////////////////////////
  // CHECK ORDER ALERTS AND SET THEM //
  useEffect(() => {
    handleError(errorOrder);
  }, [errorOrder, handleError]);
  useEffect(() => {
    handleMessage(messageOrder);
  }, [messageOrder, handleMessage]);

  ////////////////////////////////////
  // CHECK WINE ALERTS AND SET THEM //
  useEffect(() => {
    handleError(errorWine);
  }, [errorWine, handleError]);
  useEffect(() => {
    handleMessage(messageWine);
  }, [messageWine, handleMessage]);

  // SET ALERTS //
  const setMessage = (msg) => {
    dispatch(alertActions.setMessage({ message: msg }));
    setTimeout(() => {
      dispatch(alertActions.setMessage({ message: null }));
    }, 1000 * SHOW_ALERT_MESSAGE);
  };

  const setError = (err) => {
    dispatch(alertActions.setError({ error: err }));
    setTimeout(() => {
      dispatch(alertActions.setError({ error: null }));
    }, 1000 * SHOW_ALERT_MESSAGE);
  };

  return {
    error,
    message,
    setMessage,
    setError,
  };
};

export default useAlert;
