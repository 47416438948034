import React from "react";
import { useNavigate } from "react-router";

import classes from "./SharesContainer.module.css";

// COMPONENTS
import Title from "../UI/Title";
import AddNewBtn from "../UI/AddNewBtn";
import SharesTable from "./SharesTable";
import DownloadExcelBtn from "../UI/DownloadExcelBtn";

const SharesContainer = (props) => {
  const navigate = useNavigate();

  const redirectToCreateShare = (e) => {
    e.preventDefault();
    navigate("schaffen");
  };
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Title>Aktien</Title>
        <div className={classes["header-btns"]}>
          <DownloadExcelBtn model={"shares"} fileName={"aktien"} />
          <AddNewBtn onClickHandler={redirectToCreateShare}>
            Neue Aktien
          </AddNewBtn>
        </div>
      </div>
      <SharesTable hook={props.hook} />
    </div>
  );
};

export default SharesContainer;
