import React from "react";
import { Routes, Route } from "react-router-dom";

// COMPONENTS
import WinesContainer from "../components/wines/WinesContainer";
import WineContainer from "../components/wines/show-single-wine/WineContainer";
import FormWineContainer from "../components/wines/form-wine/FormWineContainer";

const WinesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<WinesContainer />} />
      <Route path="/:wineId" element={<WineContainer />} />
      <Route path="/schaffen" element={<FormWineContainer type={"create"} />} />
      <Route
        path="/redigieren/:wineId"
        element={<FormWineContainer type={"edit"} />}
      />
    </Routes>
  );
};

export default WinesRoutes;
