import React from "react";

import classes from "./Footer.module.css";

const Footer = (props) => {
  return (
    <div className={classes.container}>
      <p>
        &copy;{" "}
        {`2010-${new Date(
          Date.now()
        ).getFullYear()} von Bodega Resident Mallorca. `}
      </p>
    </div>
  );
};

export default Footer;
