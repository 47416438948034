import React from "react";
import { useNavigate } from "react-router";

import classes from "./CustomersContainer.module.css";

// COMPONENTS
import Title from "../UI/Title";
import AddNewBtn from "../UI/AddNewBtn";
import CustomersTable from "./CustomersTable";
import DownloadExcelBtn from "../UI/DownloadExcelBtn";

const CustomersContainer = (props) => {
  const navigate = useNavigate();

  const redirectToCreateCustomer = (e) => {
    e.preventDefault();
    navigate("schaffen");
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Title>Kunden</Title>
        <div className={classes["header-btns"]}>
          <DownloadExcelBtn
            model={"users"}
            fileName={"kunden"}
          ></DownloadExcelBtn>
          <AddNewBtn onClickHandler={redirectToCreateCustomer}>
            Neuer Kunde
          </AddNewBtn>
        </div>
      </div>
      <CustomersTable hook={props.hook} />
    </div>
  );
};

export default CustomersContainer;
