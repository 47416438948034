import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import classes from "./WineContainer.module.css";

// STORE
import { wineActions } from "../../../store/redux-store";

import { getWine } from "../../../store/reducers/wine-slice";

import defaultImage from "../../../assets/images/wine-bottle.png";
const IMAGE_URL = process.env.REACT_APP_WINE_IMAGE_URL_PATH;

const WineContainer = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { wine } = useSelector((state) => state.wine);
  
  useEffect(() => {
    if (wine.id && wine.id === +params.wineId) return;
    dispatch(getWine(params.wineId));
  }, [params, dispatch, wine.id]);

  useEffect(() => {
    return () => {
      dispatch(wineActions.setWine({ data: {} }));
    };
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <div className={classes["inner-container"]}>
        <div className={classes["image-box"]}>
          <img
            src={wine.image ? `${IMAGE_URL}/${wine.image}` : defaultImage}
            alt={``}
          />
        </div>
        <h2 className={classes.title}>{wine.wineName}</h2>
        <div className={classes.separator} />
        <div className={classes.info}>
          <h5>Anzahl der Aktien:</h5>
          <p>{wine.shares}</p>
        </div>
        <div className={classes.info}>
          <h5>Zuzahlung:</h5>
          <p>{(+wine.additionalPayment).toFixed(2)} €</p>
        </div>
        <div className={classes.info}>
          <h5>Lagerbestand:</h5>
          <p>{wine.stockCount}</p>
        </div>
      </div>
    </div>
  );
};

export default WineContainer;
