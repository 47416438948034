import React, { useState } from "react";
import { useNavigate } from "react-router";

import classes from "./WineCard.module.css";

// DEFAULT IMAGE
import defaultImage from "../../../assets/images/wine-bottle.png";

const WINE_IMAGE_URL_PATH = process.env.REACT_APP_WINE_IMAGE_URL_PATH;

const WineCard = (props) => {
  const navigate = useNavigate();
  const [cardHovered, setCardHovered] = useState(false);

  const hovered = (e) => {
    e.preventDefault();
    setCardHovered(true);
  };

  const notHovered = (e) => {
    e.preventDefault();
    setCardHovered(false);
  };

  const redirectToWine = (e) => {
    e.preventDefault();
    // navigate(`/weine`);
    navigate(`/weine/${props.data.id}`);
  };

  return (
    <div
      className={`${classes.container}`}
      onMouseEnter={hovered}
      onMouseLeave={notHovered}
      onClick={redirectToWine}
    >
      <div className={classes["image-box"]}>
        <img
          src={
            props.data.image === ""
              ? defaultImage
              : `${WINE_IMAGE_URL_PATH}/${props.data.image}`
          }
          alt={`Wine bottle - ${props.data.wineName}`}
          className={classes.img}
        />
      </div>
      <div
        className={classes.separator}
        style={cardHovered ? { width: `30%` } : { width: `10%` }}
      />
      <p className={classes.title}>{props.data.wineName}</p>
    </div>
  );
};

export default WineCard;
