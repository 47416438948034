import React from "react";

import classes from "./Title.module.css";

const Title = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.line} />
      <h2>{props.children}</h2>
      <div className={classes.line} />
    </div>
  );
};

export default Title;
