import React from "react";
import { Outlet } from "react-router";

import classes from "./Orders.module.css";

const Orders = (props) => {
  return (
    <div className={classes.container}>
      <Outlet />
    </div>
  );
};

export default Orders;
