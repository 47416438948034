import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import classes from "./SharesTable.module.css";

// COMPONENTS
import SharesTableRow from "./SharesTableRow";
import SharesFilters from "./SharesFilters";
import SingleTableHeader from "../UI/SingleTableHeader";

import sortDynamic from "../../helper/sort-array";

const SharesTable = (props) => {
  const { shares } = useSelector((state) => state.share);

  const [_modifiedShares, _setModifiedShares] = useState([]);
  const [_sharesRenderer, _setSharesRenderer] = useState([]);
  // const [_ordersRenderer, _setOrdersRenderer] = useState([]);

  useEffect(() => {
    const _shares = shares.map((o) => {
      return {
        id: o.shareId,
        fullName: `${o.Customer?.firstName} ${o.Customer?.lastName}`,
        email: o.Customer?.email,
        shares: o.shares,
        totalPrice: o.totalPrice,
        shareIdentificationNumber1: +o.shareIdentificationNumber.split("-")[0],
        shareIdentificationNumber2: +o.shareIdentificationNumber.split("-")[1],
        comment: o.comment,
      };
    });

    if (props.hook.sort.field && props.hook.sort.by) {
      const _sorted = _shares.sort(
        sortDynamic(props.hook.sort.field, props.hook.sort.by)
      );
      _setModifiedShares(_sorted);
    } else {
      _setModifiedShares(_shares);
    }
  }, [shares, props.hook.sort]);

  useEffect(() => {
    const _shares = _modifiedShares.map((s, i) => (
      <SharesTableRow
        data={s}
        sort={props.hook.sort}
        key={s.id}
        style={
          i % 2 !== 0 ? { backgroundColor: "var(--c-background-table)" } : {}
        }
      />
    ));
    _setSharesRenderer(_shares);
  }, [_modifiedShares, props.hook.sort]);

  return (
    <div className={classes.container}>
      <SharesFilters hook={props.hook} />
      <div className={classes.table}>
        <ul>
          <div className={classes.header}>
            <SingleTableHeader
              label={"Kunde"}
              name={"fullName"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Email"}
              name={"email"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Aktien Anzahl"}
              name={"shares"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Gesamt Preis"}
              name={"totalPrice"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Aktiennummer 1"}
              name={"shareIdentificationNumber1"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Aktiennummer 2"}
              name={"shareIdentificationNumber2"}
              hook={props.hook}
            />
            <SingleTableHeader
              label={"Kommentar"}
              name={"comment"}
              hook={props.hook}
            />
            {/* <div className={classes["table-header"]}>Kunde</div>
            <div className={classes["table-header"]}>Email</div>
            <div className={classes["table-header"]}>Aktien Anzahl</div>
            <div className={classes["table-header"]}>Gesamt Preis</div>
            <div className={classes["table-header"]}>Aktiennummer 1</div>
            <div className={classes["table-header"]}>Aktiennummer 2</div>
            <div className={classes["table-header"]}>Kommentar</div> */}
            <div
              style={{ border: "none" }}
              className={classes["table-header"]}
            ></div>
          </div>
          {_sharesRenderer}
        </ul>
      </div>
    </div>
  );
};

export default SharesTable;
