import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import handleError from "../../helper/handleError";

let URL;

URL = process.env.REACT_APP_SERVER_URL;

const initialShareState = {
  errorShare: false,
  messageShare: false,
  shares: [],
  share: {},
  totalNumOfShares: 0,
};

const shareSlice = createSlice({
  name: "share",
  initialState: initialShareState,
  reducers: {
    setErrorShare(state, action) {
      state.errorShare = action.payload.value;
    },
    setMessageShare(state, action) {
      state.messageShare = action.payload.value;
    },
    setShares(state, action) {
      state.shares = action.payload.data.shares;
      state.totalNumOfShares = action.payload.data.totalNumOfShares;
    },
    removeShareFromShares(state, action) {
      const _shares = state.shares.filter(
        (share) => share.shareId !== action.payload.value
      );
      state.shares = _shares;
    },
    setShare(state, action) {
      state.share = action.payload.data;
    },
    updateSharesQuantity(state, action) {
      const _shares = state.shares.map((s) => {
        if (s.id === action.payload.data.shareId) {
          const _copyOfS = { ...s };
          _copyOfS.stockCount =
            _copyOfS.stockCount - action.payload.data.quantity;
          return _copyOfS;
        } else {
          return s;
        }
      });

      state.shares = _shares;
    },
    setTotalNumOfShares(state, action) {
      state.totalNumOfShares = action.payload.value;
    },
  },
});

export const getShares = (token, queryStr = "") => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "get",
        url: `${URL}/shares/get-shares${queryStr}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          shareSlice.actions.setShares({
            data: {
              shares: response.data.data.shares,
              length: response.data.data.totalNumOfShares,
            },
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(err, null, dispatch, shareSlice.actions.setErrorShare);
    }
  };
};

export const getShare = (shareId, token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "get",
        url: `${URL}/shares/${shareId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          shareSlice.actions.setShare({
            data: response.data.data,
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(err, null, dispatch, shareSlice.actions.setErrorShare);
    }
  };
};

export const createShare = (userInputs, token, navigate, FN) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "post",
        url: `${URL}/shares`,
        data: userInputs,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          shareSlice.actions.setShare({
            data: response.data.data,
          })
        );
        navigate(`/aktien/${response.data.data.shareId}`);
        dispatch(FN(token));
        dispatch(
          shareSlice.actions.setMessageShare({
            value: `Aktie wurde erfolgreich kreiert.`,
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(
        err,
        `An error occurred while creating the aktien.`,
        dispatch,
        shareSlice.actions.setErrorShare
      );
    }
  };
};

export const editShare = (shareId, userInputs, token, navigate, FN) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "patch",
        url: `${URL}/shares/${shareId}`,
        data: userInputs,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          shareSlice.actions.setShare({
            data: response.data.data,
          })
        );
        navigate(`/aktien/${response.data.data.shareId}`);
        dispatch(FN(token));
        dispatch(
          shareSlice.actions.setMessageShare({
            value: `Aktien erfolgreich aktulisiert.`,
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(
        err,
        `An error occurred while deleting the aktien.`,
        dispatch,
        shareSlice.actions.setErrorShare
      );
    }
  };
};

export const deleteShare = (shareId, token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "delete",
        url: `${URL}/shares/${shareId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(shareSlice.actions.removeShareFromShares({ value: shareId }));
        dispatch(
          shareSlice.actions.setMessageShare({
            value: `Aktie wurde erfolgreich gelöscht.`,
          })
        );
      }
    } catch (err) {
      // console.error(err);
      handleError(
        err,
        "An error occurred while deleting aktien.",
        dispatch,
        shareSlice.actions.setErrorShare
      );
    }
  };
};

export default shareSlice;
