import React from "react";

import classes from "./SigninContainer.module.css";

const SigninContainer = (props) => {
  const usernameHandlers = props.hook.username;
  const passwordHandlers = props.hook.password;

  return (
    <div className={classes["form-container"]}>
      <div className={classes.background} />
      {/* <h4>Login</h4> */}
      <form className={classes.form} onSubmit={props.hook.signin}>
        <div className={classes["single-input"]}>
          <label htmlFor="username" className={classes.label}>
            Username:
          </label>
          <input
            type="text"
            name="username"
            className={classes.input}
            onChange={usernameHandlers.onChange}
            value={usernameHandlers.get}
            onBlur={usernameHandlers.onBlur}
          />
          {usernameHandlers.error && (
            <p className={classes.error}>{usernameHandlers.error}</p>
          )}
        </div>
        <div className={classes["single-input"]}>
          <label htmlFor="password" className={classes.label}>
            Password:
          </label>
          <input
            type="password"
            name="password"
            className={classes.input}
            onChange={passwordHandlers.onChange}
            value={passwordHandlers.get}
            onBlur={passwordHandlers.onBlur}
          />
          {passwordHandlers.error && (
            <p className={classes.error}>{passwordHandlers.error}</p>
          )}
        </div>
        <button className={classes.btn} type="submit">
          Login
        </button>
      </form>
    </div>
  );
};

export default SigninContainer;
