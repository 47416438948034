const sortDynamic = (field, order = "asc") => {
  return function (a, b) {
    let valueA = a[field];
    let valueB = b[field];

    // Handle case-insensitive comparison for strings
    if (typeof valueA === "string") {
      valueA = valueA.toLowerCase();
    }
    if (typeof valueB === "string") {
      valueB = valueB.toLowerCase();
    }

    let comparison = 0;

    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }

    return order === "asc" ? comparison : -comparison;
  };
};

export default sortDynamic;
