import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import classes from "./WineCard.module.css";

// STORE
import { orderActions } from "../../store/redux-store";
import { deleteWine, getWines } from "../../store/reducers/wine-slice";

import { BsThreeDotsVertical, BsBoxSeam } from "react-icons/bs";
// import { BsThreeDotsVertical } from "react-icons/bs";

// DEFAULT IMAGE
import defaultImage from "../../assets/images/wine-bottle.png";
import bgImg from "../../assets/images/wine-mark.png";

const WINE_IMAGE_URL_PATH = process.env.REACT_APP_WINE_IMAGE_URL_PATH;

const SingleInfo = (props) => {
  return (
    <div
      className={`${props.class} ${classes["single-info"]}`}
      onClick={props.onClick}
      style={props.onClick ? { cursor: "pointer" } : {}}
    >
      {props.label && <p className={classes["label"]}>{props.label}</p>}
      {props.icon && props.icon}
      <p className={classes["value"]}>{props.value}</p>
    </div>
  );
};

const WineCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isAuthenticated, token } = useSelector((state) => state.auth);
  const { newOrders } = useSelector((state) => state.order);

  const [hovered, setHovered] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [rndRotate, setRndRotate] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);

  const setShowDropdownHandler = (value) => {
    setShowDropdown(value);
  };

  const showDropdownHandler = (e) => {
    e.preventDefault();
    setShowDropdownHandler(!showDropdown);
  };

  const onHoverHandler = (e) => {
    e.preventDefault();
    setHovered(true);
    setRndRotate(Math.random() * 360);
  };

  const onHoverOutHandler = (e) => {
    e.preventDefault();
    setHovered(false);
  };

  const onQuantityChangeHandler = (e) => {
    e.preventDefault();
    setQuantity(+e.target.value);
  };

  const onFocusHandler = (e) => {
    e.preventDefault();
    e.target.select();
  };

  const onBlurHandler = (e) => {
    e.preventDefault();

    if (props.data.stockCount - +props.data.totalOrders < quantity) {
      // console.log("There is no enough items in stock!");
      // dispatch(
      //   orderActions.setErrorOrder({
      //     value: "There is no enough items in stock!",
      //   })
      // );
      return;
    }

    const _requiredShares = props.data.shares * quantity;

    if (_requiredShares > user.shares.availableShares) {
      // console.log("You dont have enough shares!");
      // dispatch(
      //   orderActions.setErrorOrder({ value: "You dont have enough aktien!" })
      // );
      return;
    }
  };

  const onCreateOrder = (e) => {
    e.preventDefault();

    if (quantity === 0) return;

    const _reservedShares = newOrders.reduce((a, b) => {
      return a + b.wine.shares * b.quantity;
    }, 0);

    const availableUserShares = user.shares.availableShares - _reservedShares;

    const existingOrder = newOrders.find((o) => o.wineId === props.data.id);

    let totalStockCount = props.data.stockCount - +props.data.totalOrders;

    if (existingOrder)
      totalStockCount = totalStockCount - existingOrder.quantity;

    if (totalStockCount < quantity) {
      // console.log("There is no enough wines in stock!");
      dispatch(
        orderActions.setErrorOrder({
          value: "Nicht genügend Weine auf Lager !",
        })
      );
      return;
    }

    const _requiredShares = props.data.shares * quantity;

    if (_requiredShares > availableUserShares) {
      // console.log("You dont have enough shares!");
      dispatch(
        orderActions.setErrorOrder({
          value: "Anzahl der Aktien nicht genügend.",
        })
      );
      return;
    }

    if (existingOrder) {
      const _copyOfOrder = { ...existingOrder };

      _copyOfOrder.quantity = _copyOfOrder.quantity + quantity;
      dispatch(orderActions.modifySingleOrder({ data: _copyOfOrder }));
    } else {
      const newOrder = {
        id: Math.random(),
        wine: props.data,
        wineId: props.data.id,
        quantity: +quantity,
        pickup: 1,
      };

      dispatch(orderActions.addNewOrders({ data: newOrder }));
    }

    setQuantity(0);
  };

  const onDeleteWineHandler = (e) => {
    e.preventDefault();

    if (!isAuthenticated || !token || user.isAdmin === 0) return;

    dispatch(deleteWine(props.data.id, token, getWines));
  };

  const redirectToWeine = (e) => {
    e.preventDefault();
    navigate(`${props.data.id}`);
  };

  const redirectToEditWineHandler = (e) => {
    e.preventDefault();
    navigate(`redigieren/${props.data.id}`);
  };

  return (
    <div
      className={classes.container}
      onMouseEnter={onHoverHandler}
      onMouseLeave={onHoverOutHandler}
    >
      {isAuthenticated && user.isAdmin === 1 && (
        <div className={classes.dropdown}>
          <div
            className={classes["dropdown-icon"]}
            onClick={showDropdownHandler}
          >
            <BsThreeDotsVertical />
          </div>
          {showDropdown && (
            <div className={classes["dropdown-container"]}>
              <div
                className={classes["single-dropdown"]}
                onClick={redirectToEditWineHandler}
              >
                Bearbeiten
              </div>
              <div
                className={classes["single-dropdown"]}
                onClick={onDeleteWineHandler}
              >
                Löschen
              </div>
            </div>
          )}
        </div>
      )}
      <div className={classes["image-box"]}>
        <img
          src={
            props.data.image === ""
              ? defaultImage
              : `${WINE_IMAGE_URL_PATH}/${props.data.image}`
          }
          alt={props.data.wineName}
          className={classes.img}
        />
      </div>
      <div className={classes.header}>
        <SingleInfo
          class={classes.title}
          label={""}
          value={props.data.wineName}
          onClick={redirectToWeine}
        />
      </div>
      <div
        className={classes["line-horizontal"]}
        style={
          hovered
            ? { width: "70%", backgroundColor: "var(--c-primary-red)" }
            : { width: "100%" }
        }
      />
      <div className={classes["price"]}>
        <SingleInfo label={"Anzahl der Aktien:"} value={props.data.shares} />
        <SingleInfo
          label={"Zuzahlung:"}
          value={`${props.data.additionalPayment} €`}
        />
      </div>
      {/* {user.isAdmin === 0 && user.shares && ( */}
      <div className={classes["order"]}>
        <SingleInfo
          label={""}
          icon={<BsBoxSeam className={classes.icon} />}
          value={props.data.stockCount - +props.data.totalOrders}
          // value={props.data.stockCount}
        />
        {user.isAdmin === 1 && (
          <SingleInfo
            label={"Bestellungen gesamt:"}
            value={props.data.totalOrders || 0}
          />
        )}
        {user.isAdmin === 0 && user.shares && (
          <div className={classes["order-wine__container"]}>
            <input
              type="number"
              value={quantity}
              onFocus={onFocusHandler}
              onChange={onQuantityChangeHandler}
              onBlur={onBlurHandler}
            />
            <button type="click" onClick={onCreateOrder}>
              Bestellung
            </button>
          </div>
        )}
      </div>
      {/* )} */}
      {/* <SingleInfo label={"Lagerbestand"} icon={<BsBoxSeam  />} value={props.data.stockCount} /> */}
    </div>
  );
};

export default WineCard;
